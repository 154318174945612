import { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import Box from '@mui/material/Box'
// import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useGroup } from '../../context/Group.jsx'

import LogoutModal from '../../components/LogoutModal/LogoutModal.jsx'
import { dropdown, dropdownItem } from '../../shared/muiStyles/dropdown'
// import user from '../../assets/img/user.svg'

import styles from './Group.module.scss'

const UserProfile = () => {
  const [anchorProfile, setAnchorProfile] = useState(null)
  const [profileName, setProfileName] = useState('')
  const openProfile = Boolean(anchorProfile)
  const [isLogoutOpened, setLogoutStatus] = useState(false)
  const { groupData } = useGroup()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { instance, accounts } = useMsal()

  useEffect(() => {
    if (accounts && accounts[0]) {
      const { name } = accounts[0]
      setProfileName(name)
    }
  }, [accounts])

  const profileClick = (event) => {
    setAnchorProfile(event.currentTarget)
  }
  const profileClose = () => {
    setAnchorProfile(null)
  }
  const logoutClick = () => {
    setLogoutStatus(true)
  }
  const modalClose = () => {
    setLogoutStatus(false)
  }
  const logoutUser = () => {
    instance.logoutRedirect()
    instance.logoutPopup({
      postLogoutRedirectUri: '/',
    })
    setLogoutStatus(false)
  }

  return (
    <>
      <Box
        className={styles.user}
        onClick={profileClick}
      >
        {/* {groupData.isLoading ? (
          <Skeleton
            variant="circular"
            width={40}
            height={40}
            className={styles.userImg}
          ></Skeleton>
        ) : (
          <Avatar
            className={styles.userImg}
            alt="user"
            src={user}
          />
        )} */}
        {groupData.isLoading && !isSmall ? (
          <Skeleton
            width={70}
            height={20}
            className={styles.userName}
          ></Skeleton>
        ) : (
          <span className={styles.userName}>
            {profileName.split('.').join(' ')}
          </span>
        )}
      </Box>
      <Menu
        anchorEl={anchorProfile}
        id="account-menu"
        open={openProfile}
        onClick={profileClose}
        PaperProps={{
          elevation: 0,
          sx: dropdown,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Link
          to="/profile"
          style={{ textDecoration: 'none' }}
        >
          <MenuItem sx={dropdownItem}>My Profile</MenuItem>
        </Link>

        <MenuItem
          sx={dropdownItem}
          onClick={logoutClick}
        >
          Logout
        </MenuItem>
      </Menu>
      <LogoutModal
        open={isLogoutOpened}
        onClose={modalClose}
        logoutClick={logoutUser}
      />
    </>
  )
}

export default UserProfile
