import Box from '@mui/material/Box'
import classnames from 'classnames'

import { useGroup } from '../../context/Group.jsx'

import cardStyles from '../../layouts/Card/Card.module.scss'
import gridStyles from './GridPurchases.module.scss'
import { isJuniorOrSeniorCompany } from '../../utils/common.js'

const defaultGridPurchasesData = {
  Environmental_Rate: 0,
  Energy_Rate: 0,
  // brown_energy_percent: 0,
  Network_Energy_Rate: 0,
  // green_energy_percent: 0,
  Regulatory_and_other_Rate: 0,
}

const GridPurchases = () => {
  const { groupHelpers, groupData } = useGroup()
  const companyName = groupData.activeGroup ? groupData.activeGroup.company_name : ''
  const isJuniorOrSenior = isJuniorOrSeniorCompany(companyName);

  const {
    Environmental_Rate,
    Energy_Rate,
    // brown_energy_percent,
    Network_Energy_Rate,
    // green_energy_percent,
    Regulatory_and_other_Rate,
  } = groupHelpers.getActiveGroupData(
    'grid_purchases',
    defaultGridPurchasesData
  )
  const normGridPurchasesDataData = {
    Energy_Rate: {
      name: 'Energy Rate',
      // percent: brown_energy_percent,
      amount: Energy_Rate,
    },
    Network_Energy_Rate: {
      name: 'Network Energy Rate',
      // percent: green_energy_percent,
      amount: Network_Energy_Rate,
    },
    Environmental_Rate: {
      name: 'Environmental Rate',
      amount: Environmental_Rate,
    },
    Regulatory_and_other_Rate: {
      name: 'Regulatory and other Rate',
      amount: Regulatory_and_other_Rate,
    },
  }
  return (
    <Box
      className={classnames(
        cardStyles.card,
        cardStyles.cardOperations,
        cardStyles.gridPurchases
      )}
    >
      <div className={cardStyles.innerTitle}>Grid Purchases</div>

      {Object.keys(normGridPurchasesDataData).map((key) => {
        if (isJuniorOrSenior && (key === 'Network_Energy_Rate' || key === 'Environmental_Rate' || key === 'Regulatory_and_other_Rate')) {
          return null;
        }

        const name = normGridPurchasesDataData[key].name
        const percent = normGridPurchasesDataData[key].percent
        const amount = normGridPurchasesDataData[key].amount

        return (
          <div
            key={key}
            className={gridStyles.row}
          >
            <div>
              <div className={gridStyles.rowHeader}>{name}</div>
              {percent && (
                <div className={gridStyles.rowSubHeader}>{percent}%</div>
              )}
            </div>
            <div className={gridStyles.rowValue}>{amount}c</div>
          </div>
        )
      })}
    </Box>
  )
}

export default GridPurchases
