import Chip from '../Chip/Chip.jsx'

import styles from './StatusData.module.scss'

const StatusData = ({ status, list }) => {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.rowHeader}>Status:</div>
        <Chip
          value={status}
          type={status}
        />
      </div>

      <div>
        {Object.keys(list).map((key) => (
          <div
            key={key}
            className={styles.row}
          >
            <div className={styles.rowHeader}>{key.replace('_', ' ')}:</div>
            <div className={styles.rowValue}>{list[key]}</div>
          </div>
        ))}
      </div>
    </>
  )
}

export default StatusData
