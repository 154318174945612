import classnames from 'classnames'

import alarmIcon from '../../assets/img/alarm.svg'

import styles from './Alarms.module.scss'

const alarmsList = []

const Alarms = () => {
  return (
    <div
      className={classnames(styles.wrapper, {
        [styles.empty]: alarmsList.length == 0,
      })}
    >
      {alarmsList.length > 0 ? (
        alarmsList.map((alarm, index) => (
          <div
            className={styles.row}
            key={alarm + index}
          >
            <img
              src={alarmIcon}
              className={styles.icon}
              alt="alarm icon"
            />
            {alarm}
          </div>
        ))
      ) : (
        <div>No alarms for now</div>
      )}
    </div>
  )
}

export default Alarms
