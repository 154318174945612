import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { msalConfig } from './utils/authConfig'

import App from './App.jsx'
import ScrollToTop from './components/ScrollToTop/ScrollToTop.jsx'
import { GroupProvider } from './context/Group.jsx'

const root = ReactDOM.createRoot(document.getElementById('root'))
//custom mui breakpoints
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xsSm: 600,
      sm: 768,
      mdm: 900,
      md: 1024,
      lg: 1200,
    },
  },
})
const msalInstance = new PublicClientApplication(msalConfig)

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <GroupProvider>
        <Router>
          <ThemeProvider theme={theme}>
            <ScrollToTop />
            <App />
          </ThemeProvider>
        </Router>
      </GroupProvider>
    </MsalProvider>
  </React.StrictMode>
)
