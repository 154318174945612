import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { useEffect } from 'react'

import { useGroup } from '../../../context/Group.jsx'
import { transformKwhRank } from '../../../utils/transformKwhRank'

import './PerformanceChart.scss'

const defaultData = {
  demand: 0,
  generation: 0,
  grid: 0,
}

function PerformanceChart() {
  const { groupHelpers } = useGroup()
  const performanceData = groupHelpers.getActiveGroupData(
    'performance',
    defaultData
  )
  const { demand, generation, grid } = performanceData

  useEffect(() => {
    renderChart()
  })

  function getLiters(energySource) {
    const result = (energySource / demand) * 100
    return Math.round(result)
  }

  function renderChart() {
    am4core.useTheme(am4themes_animated)
    am4core.addLicense('ch-custom-attribution')

    const chart = am4core.create('performanceChart', am4charts.PieChart)

    // Add data
    chart.data = [
      {
        energy_type: 'Grid',
        litres: getLiters(grid),
        color: am4core.color('#57B0F4'),
      },
      {
        energy_type: 'Generation',
        litres: getLiters(generation),
        color: am4core.color('#EA5449'),
      },
    ]

    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries())
    pieSeries.dataFields.value = 'litres'
    pieSeries.dataFields.category = 'energy_type'
    pieSeries.slices.template.propertyFields.fill = 'color'
    pieSeries.slices.template.cornerRadius = 100
    pieSeries.slices.template.innerCornerRadius = 100
    // Let's cut a hole in our Pie chart the size of 90% the radius
    chart.innerRadius = am4core.percent(93)

    // Disable and labels
    pieSeries.labels.template.disabled = true
    pieSeries.ticks.template.disabled = true

    //custom tooltip
    const tooltipHTML =
      '<p style="font-family: Inter; font-weight: 400; font-size: 12px; color: #001B40; padding: 4px 8px; margin: 0;">{value}%</p>'
    pieSeries.slices.template.tooltipHTML = tooltipHTML
    pieSeries.tooltip.getFillFromObject = false
    pieSeries.tooltip.background.fill = am4core.color('#FFFFFF')
    pieSeries.tooltip.background.cornerRadius = 10
    pieSeries.tooltip.background.strokeWidth = 0
    pieSeries.tooltip.pointerOrientation = 'left'
    const shadow = pieSeries.tooltip.background.filters.getIndex(0)
    shadow.dx = 4
    shadow.dy = 4
    shadow.blur = 10
    shadow.color = am4core.color('#DBE2E9')

    // Add a legend
    chart.legend = new am4charts.Legend()
    chart.legend.fontSize = 14
    //remove % from legend
    chart.legend.valueLabels.template.disabled = true
    chart.legend.fontFamily = 'Inter'
    chart.legend.fill = '#001B40'
    chart.legend.useDefaultMarker = true
    const marker = chart.legend.markers.template.children.getIndex(0)
    marker.cornerRadius(50, 50, 50, 50)
    marker.width = 8
    marker.height = 8
    marker.dy = 6
    marker.dx = 12
    //customize disabled lagend and marker
    const disabledLagendLabel =
      chart.legend.labels.template.states.getKey('active')
    disabledLagendLabel.properties.fill = am4core.color('#E4EAF0')
    const disabledMarker = marker.states.create('active')
    disabledMarker.properties.fillOpacity = 0.07
    disabledMarker.properties.strokeOpacity = 0.07

    chart.responsive.rules.push({
      relevant: function (target) {
        if (target.pixelHeight === 264 || target.pixelHeight === 210) {
          return true
        }

        return false
      },
      state: function (target, stateId) {
        if (target instanceof am4charts.Legend) {
          const stateLegend = target.states.create(stateId)
          stateLegend.properties.fontSize = 10
          return stateLegend
        }
      },
    })
  }

  return (
    <div className="performanceChart__container">
      <div
        className="performanceChart__chart"
        id="performanceChart"
      />
      <div className="performanceChart__text-container">
        <h6>Demand</h6>
        <h2>
          {demand >= 1000
            ? `${transformKwhRank(demand)} MWh`
            : `${demand?.toFixed(1)} kWh`}
        </h2>
      </div>
    </div>
  )
}

export default PerformanceChart
