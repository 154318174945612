import { useEffect, useState } from 'react'
import { Stage, Layer } from 'react-konva'
import useResizeObserver from 'use-resize-observer'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useGroup } from '../../../context/Group.jsx'

import Battery from './Battery.jsx'
import Demand from './Demand.jsx'
import Grid from './Grid.jsx'
import Solar from './Solar.jsx'
import DashLine from './DashLine.jsx'
import EnergyFlowArrow from './EnergyFlowArrow.jsx'

import { getDimensionsCanvasElements } from '../../../utils/getDimensionsCanvasElements'

import './EnergyFlows.scss'

const initialCoefs = {
  start: 0.1,
  end: 0.6,
}

const defaultEnergyData = {
  demand: 0,
  gridCost: 0,
  solarToGrid: 0,
  solarToBattery: 0,
  solarToDemand: 0,
  gridToBattery: 0,
  batteryToGrid: 0,
  gridToDemand: 0,
  batteryToDemand: 0,
}

const EnergyFlowsChart = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { ref: containerRef, width: containerWidth = 1 } = useResizeObserver()
  const { groupHelpers } = useGroup()
  const solarData = groupHelpers.getActiveGroupData('solar', {})
  const batteryData = groupHelpers.getActiveGroupData('battery', {})
  const {
    demand,
    gridCost,
    solarToGrid,
    solarToBattery,
    solarToDemand,
    gridToBattery,
    batteryToGrid,
    gridToDemand,
    batteryToDemand,
  } = groupHelpers.getActiveGroupData('energy_flows_systems', defaultEnergyData)
  const [linePosCoef, setLinePosCoef] = useState(initialCoefs)

  useEffect(() => {
    //interval for arrows animation
    const refreshAnimationInterval = setInterval(() => {
      setLinePosCoef((prevState) => {
        const stepIncrement = 0.1
        const minimalValueStart = 0.1
        const maxStepValueStart = 0.5
        const minimalValueEnd = 0.6
        const maxStepValueEnd = 1
        const updatedCoords = {}

        Object.keys(prevState).map((coord) => {
          const prevValue = prevState[coord]
          const nextValueStart =
            prevValue + stepIncrement > maxStepValueStart
              ? minimalValueStart
              : +(prevValue + stepIncrement).toFixed(2)
          const nextValueEnd =
            prevValue + stepIncrement > maxStepValueEnd
              ? minimalValueEnd
              : +(prevValue + stepIncrement).toFixed(2)
          const nextValue = coord === 'start' ? nextValueStart : nextValueEnd
          updatedCoords[coord] = nextValue
        })

        return updatedCoords
      })
    }, 700)
    return () => clearInterval(refreshAnimationInterval)
  }, [linePosCoef])

  const {
    canvasWidth,
    canvasHeight,
    centerX,
    fontSize,
    isBigContainer,
    solarImgWidth,
    solarImgHeight,
    demandImgWidth,
    demandImgHeight,
    batteryImgWidth,
    batteryImgHeight,
    gridImgWidth,
    gridImgHeight,
    coords,
  } = getDimensionsCanvasElements(containerWidth, isSmall)

  return (
    <div
      className="energyFlow__container"
      ref={containerRef}
    >
      <Stage
        width={canvasWidth}
        height={canvasHeight}
      >
        <Layer>
          <Grid
            x={centerX - gridImgWidth / 2}
            y={0}
            imgWidth={gridImgWidth}
            imgHeight={gridImgHeight}
            canvasWidth={canvasWidth}
            fontSize={fontSize}
            value={gridCost}
          />
          <Solar
            x={coords.solar.x}
            y={coords.solar.y}
            imgWidth={solarImgWidth}
            imgHeight={solarImgHeight}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
            fontSize={fontSize}
            data={solarData}
          />
          <Demand
            x={coords.demand.x}
            y={coords.demand.y}
            imgWidth={demandImgWidth}
            imgHeight={demandImgHeight}
            canvasWidth={canvasWidth}
            canvasHeight={canvasHeight}
            fontSize={fontSize}
            value={demand}
          />
          <Battery
            x={coords.battery.x}
            y={coords.battery.y}
            canvasWidth={canvasWidth}
            imgWidth={batteryImgWidth}
            imgHeight={batteryImgHeight}
            fontSize={fontSize}
            data={batteryData}
            isSmall={isSmall}
            isBigContainer={isBigContainer}
          />
          {/*from solar to grid*/}
          {solarToGrid > 0 && (
            <EnergyFlowArrow
              canvasWidth={canvasWidth}
              containerWidth={containerWidth}
              isSmall={isSmall}
              x={coords.arrows.solarGrid.x}
              y={coords.arrows.solarGrid.y}
              points={coords.arrows.solarGrid.points}
              fontSize={fontSize}
              caption={{
                text: `${solarToGrid.toFixed(2)} kW`,
                x: 'start',
                y: '  ',
              }}
              name="solar-grid"
              coef={linePosCoef}
            />
          )}
          {(solarToGrid == 0 || !solarToGrid) && (
            <DashLine
              x={coords.arrows.solarGrid.x}
              y={coords.arrows.solarGrid.y}
              points={coords.arrows.solarGrid.points}
            />
          )}
          {/*from solar to battery*/}
          {solarToBattery < 0 && (
            <EnergyFlowArrow
              canvasWidth={canvasWidth}
              containerWidth={containerWidth}
              isSmall={isSmall}
              x={coords.arrows.solarBattery.x}
              y={coords.arrows.solarBattery.y}
              points={coords.arrows.solarBattery.points}
              fontSize={fontSize}
              caption={{
                text: `${-1*solarToBattery.toFixed(2)} kW`,
                x: 'start',
                y: 'top',
              }}
              name="solar-grid"
              coef={linePosCoef}
            />
          )}
          {(solarToBattery == 0 || !solarToBattery) && (
            <DashLine
              x={coords.arrows.solarBattery.x}
              y={coords.arrows.solarBattery.y}
              points={coords.arrows.solarBattery.points}
            />
          )}
          {/*from solar to demand*/}
          {solarToDemand > 0 && (
            <EnergyFlowArrow
              canvasWidth={canvasWidth}
              containerWidth={containerWidth}
              isSmall={isSmall}
              x={coords.arrows.solarDemand.x}
              y={coords.arrows.solarDemand.y}
              points={coords.arrows.solarDemand.points}
              fontSize={fontSize}
              caption={{
                text: `${solarToDemand.toFixed(2)} kW`,
                x: 'end',
                y: 'top',
              }}
              straightLine
              name="solar-demand"
              coef={linePosCoef}
            />
          )}
          {(solarToDemand == 0 || !solarToDemand) && (
            <DashLine
              x={coords.arrows.solarDemand.x}
              y={coords.arrows.solarDemand.y}
              points={coords.arrows.solarDemand.points}
            />
          )}
          {/*from grid to battery and battery to grid*/}
          {gridToBattery > 0 && (
            <EnergyFlowArrow
              canvasWidth={canvasWidth}
              containerWidth={containerWidth}
              isSmall={isSmall}
              x={coords.arrows.gridBattery.x}
              y={coords.arrows.gridBattery.y}
              points={coords.arrows.gridBattery.points}
              fontSize={fontSize}
              caption={{
                text: `${gridToBattery.toFixed(2)} kW`,
                x: 'end',
                y: 'top',
              }}
              straightLine
              name="grid-battery"
              coef={linePosCoef}
              rotation={-90}
            />
          )}
          {batteryToGrid > 0 && (
            <EnergyFlowArrow
              canvasWidth={canvasWidth}
              containerWidth={containerWidth}
              isSmall={isSmall}
              x={coords.arrows.batteryGrid.x}
              y={coords.arrows.batteryGrid.y}
              points={coords.arrows.batteryGrid.points}
              fontSize={fontSize}
              caption={{
                text: `${batteryToGrid.toFixed(2)} kW`,
                x: 'end',
                y: 'top',
              }}
              straightLine
              name="battery-grid"
              coef={linePosCoef}
              rotation={90}
            />
          )}
          {(gridToBattery == 0 ||
            !gridToBattery ||
            batteryToGrid == 0 ||
            !batteryToGrid) && (
            <DashLine
              x={coords.arrows.gridBattery.x}
              y={coords.arrows.gridBattery.y}
              points={coords.arrows.gridBattery.points}
            />
          )}
          {/*from grid to demand*/}
          {gridToDemand > 0 && (
            <EnergyFlowArrow
              canvasWidth={canvasWidth}
              containerWidth={containerWidth}
              isSmall={isSmall}
              x={coords.arrows.gridDemand.x}
              y={coords.arrows.gridDemand.y}
              points={coords.arrows.gridDemand.points}
              fontSize={fontSize}
              caption={{
                text: `${gridToDemand.toFixed(2)} kW`,
                x: 'end',
                y: 'top',
              }}
              name="grid-demand"
              coef={linePosCoef}
            />
          )}
          {(gridToDemand == 0 || !gridToDemand) && (
            <DashLine
              x={coords.arrows.gridDemand.x}
              y={coords.arrows.gridDemand.y}
              points={coords.arrows.gridDemand.points}
            />
          )}
          {/*from battery to demand*/}
          {batteryToDemand > 0 && (
            <EnergyFlowArrow
              canvasWidth={canvasWidth}
              containerWidth={containerWidth}
              isSmall={isSmall}
              x={coords.arrows.batteryDemand.x}
              y={coords.arrows.batteryDemand.y}
              points={coords.arrows.batteryDemand.points}
              fontSize={fontSize}
              caption={{
                text: `${batteryToDemand.toFixed(2)} kW`,
                x: 'end',
                y: 'top',
              }}
              name="battery-demand"
              coef={linePosCoef}
            />
          )}
          {(batteryToDemand == 0 || !batteryToDemand) && (
            <DashLine
              x={coords.arrows.batteryDemand.x}
              y={coords.arrows.batteryDemand.y}
              points={coords.arrows.batteryDemand.points}
            />
          )}
        </Layer>
      </Stage>
    </div>
  )
}

export default EnergyFlowsChart
