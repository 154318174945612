import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Card from '../../layouts/Card/Card.jsx'
import ProgressToNet from '../../components/ProgressToNet/ProgressToNet.jsx'
import PotentialSavings from '../../components/PotentialSavings/PotentialSavings.jsx'
import Operations from '../../layouts/Operations/Operations.jsx'
import Performance from '../../components/Performance/Performance.jsx'

import PerformanceIcon from '../../assets/icons/Performance.jsx'
import ReportsIcon from '../../assets/icons/Reports.jsx'
import OperationsIcon from '../../assets/icons/Operations.jsx'
import cardBg from '../../assets/img/card_bg.svg'
import { useGroup } from '../../context/Group.jsx'
import { isJuniorOrSeniorCompany } from '../../utils/common.js'

const Home = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { groupData } = useGroup()
  const companyName = groupData.activeGroup ? groupData.activeGroup.company_name : ''

  return (
    <Grid 
      container
      direction="row" 
      rowSpacing={isSmall ? 2 : 5}
      columnSpacing={isSmall ? 0 : 4}
    >
      <Grid
        item
        xs={12}
      >
        <Card
          singleCard
          orientation="start"
          wrapperModifier="w-100"
          cardModifier="pb-0"
        >
          <ProgressToNet />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={!isJuniorOrSeniorCompany(companyName) ? 6 : 12}
      >
        <Card
          title="Performance"
          icon={
            <PerformanceIcon
              width="24"
              height="24"
              color="#001B40" 
            />
          }
          link="/performance"
          singleCard
        >
          <Performance />
        </Card>
      </Grid>
      {
        !isJuniorOrSeniorCompany(companyName) ? (
          <Grid
            item
            xs={12}
            md={6}
          >
            <Card
              title="Potential Savings"
              icon={
                <ReportsIcon
                  width="26"
                  height="30"
                  color="#001B40"
                />
              }
              link="/reports"
              background={cardBg}
              isReport
              singleCard
            >
              <PotentialSavings />
            </Card>
          </Grid>
        ) : ''
      }

      <Grid
        item
        xs={12}
      >
        <Card
          title="Operations"
          icon={
            <OperationsIcon
              width="24"
              height="24"
              color="#001B40"
            />
          }
          link="/operations"
          background={cardBg}
        >
          <Operations />
        </Card>
      </Grid>
    </Grid>
  )
}

export default Home
