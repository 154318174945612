import Grid from '@mui/material/Grid'

import './Sites.scss'

import Map from '../../components/Map/Map.jsx'

const Sites = () => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
      >
        <Map />
      </Grid>
    </Grid>
  )
}

export default Sites
