const drawer = {
  position: 'relative',
  border: 'none',
  zIndex: 3,
  height: '100%',
  '& .MuiPaper-root': {
    overflow: 'visible',
    border: 'none',
    position: 'relative',
    background: 'inherit',
    maxWidth: '100%',
    display: 'flex',
  },
}

const sidebarIconButton = {
  bgcolor: '#FFFFFF',
  zIndex: 4,
  transition: 'all 0.3s ease-in-out',
  marginLeft: 'auto',
  marginTop: 'auto',
  '&:active': {
    bgcolor: '#FFFFFF',
    '& .MuiSvgIcon-root': {
      color: '#001B40',
    },
  },
  '&:hover': {
    bgcolor: '#F3F7F9',
    '& .MuiSvgIcon-root': {
      color: '#001B40',
    },
  },
}

export { drawer, sidebarIconButton }
