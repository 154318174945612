const weatherTypes = {
  sun: 'sun',
  cloud: 'cloud',
  rain: 'rain',
  snow: 'snow',
  partlyCloudy: 'partlyCloudy',
  thunderstorm: 'thunderstorm',
}

export default weatherTypes
