import classnames from 'classnames'

import { batteryStatuses } from '../../utils/constants/batteryStatuses'
import { solarPanelStatuses } from '../../utils/constants/solarPanelStatuses'

import chipStyles from './Chip.module.scss'

const Chip = ({ value, type }) => {
  const isPositiveValue = type === 'default' && value > 0
  const isNegativeValue = type === 'default' && value < 0
  const isPositiveStatus =
    type === batteryStatuses.charging || type === solarPanelStatuses.optimal
  const isNegativeStatus =
    type === batteryStatuses.discharging ||
    type === solarPanelStatuses.subOptimal
  const isErrorStatus =
    type === batteryStatuses.error || type === solarPanelStatuses.offline
  const isIdleStatus = type === batteryStatuses.idle
  const isOnlineStatus = type === solarPanelStatuses.online

  return (
    <span
      className={classnames(chipStyles.chip, {
        [chipStyles.positiveValue]: isPositiveValue,
        [chipStyles.negativeValue]: isNegativeValue,
        [chipStyles.positiveStatus]: isPositiveStatus,
        [chipStyles.negativeStatus]: isNegativeStatus,
        [chipStyles.errorStatus]: isErrorStatus,
        [chipStyles.onlineStatus]: isOnlineStatus,
        [chipStyles.idleStatus]: isIdleStatus,
      })}
    >
      {type === 'default'
        ? `${value > 0 ? '+' : ''}${value}%`
        : value.replace('_', '-')}
    </span>
  )
}

export default Chip
