import { useState } from 'react'
import classnames from 'classnames'

import Sidebar from './Sidebar/Sidebar.jsx'
import Group from './Group/Group.jsx'

import styles from './DashboardLayout.module.scss'

const DashboardLayout = ({ children }) => {
  const [isSidebarOpen, setSidebarStatus] = useState(true)
  const toggleSidebar = () => {
    setSidebarStatus(!isSidebarOpen)
  }

  return (
    <main className={styles.dashboard}>
      <Sidebar
        open={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />
      <div
        className={classnames(styles.dashboardContainer, {
          [styles.open]: isSidebarOpen,
        })}
      >
        <Group />
        {children}
      </div>
    </main>
  )
}

export default DashboardLayout
