import React, { useMemo, useState } from 'react'
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider,
  AzureMapsProvider,
  AzureMapPopup,
} from 'react-azure-maps'
import { AuthenticationType, data } from 'azure-maps-control'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react'

import { mapAPiKey } from '../../utils/constants/variables'
import { useGroup } from '../../context/Group.jsx'
import { loginRequest } from '../../utils/authConfig'

import Chip from '../Chip/Chip.jsx'
import Spinner from '../Spinner/Spinner.jsx'

import styles from './Map.module.scss'
// import pin from '../../assets/img/map/pin.svg'

const renderPoint = (data) => {
  return (
    <AzureMapFeature
      key={data.id}
      id={data.id}
      type="Point"
      coordinate={[data.longitude, data.latitude]}
      properties={{
        id: data.id,
        popUpProp: data,
      }}
    />
  )
}

const Map = () => {
  const { instance, accounts } = useMsal(useMsalAuthentication())
  const isAuth = useIsAuthenticated()
  const [popupOptions, setPopupOptions] = useState({})
  const [popupSelectedSite, setPopupSelectedSite] = useState(0)
  const { mapData, groupData, groupActions } = useGroup()
  const { isLoading, isLoadingBackground } = groupData

  //map options
  const option = useMemo(() => {
    if (mapData.length > 0) {
      const coords = mapData.reduce((acc, item) => {
        acc = {
          latitude: acc.latitude + item.latitude,
          longitude: acc.longitude + item.longitude,
        }
        return acc
      })

      const centerCoords = [
        coords.longitude / mapData.length,
        coords.latitude / mapData.length,
      ]

      return {
        authOptions: {
          authType: AuthenticationType.subscriptionKey,
          subscriptionKey: mapAPiKey,
        },
        center: centerCoords,
        zoom: 5,
        view: 'Auto',
        style: 'grayscale_light',
        showFeedbackLink: false,
        showLogo: false,
      }
    }
  }, [mapData])
  const controls = [
    {
      controlName: 'ZoomControl',
      options: { position: 'bottom-right' },
    },
  ]
  const userInteraction = {
    dragRotateInteraction: false,
  }

  const memoizedMarkerRender = useMemo(
    () => mapData.map((el) => renderPoint(el)),
    [mapData]
  )

  const handleSiteClick = async (group) => {
    if (isAuth) {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      const { accessToken, idToken } = response
      groupActions.getSiteMapData(group, accessToken, idToken)
    }
  }

  const popupProperties =
    popupSelectedSite > 0
      ? mapData.find((site) => site.id === popupSelectedSite)
      : {}

  return mapData.length > 0 && !isLoading ? (
    <AzureMapsProvider>
      <>
        <h2 className={styles.header}>Site Map</h2>
        <div className={styles.map}>
          <AzureMap
            options={option}
            controls={controls}
            userInteraction={userInteraction}
            events={{
              click: (e) => {
                //if click not on popup, close it
                if (!e.shapes[0]) {
                  setPopupOptions({
                    ...popupOptions,
                    isVisible: false,
                  })
                }
              },
            }}
          >
            <AzureMapDataSourceProvider
              id={'MultiplePoint AzureMapDataSourceProvider'}
            >
              <AzureMapLayerProvider
                id={'MultiplePoint AzureMapLayerProvider'}
                options={{
                  iconOptions: {
                    image: 'pin-darkblue',
                  },
                }}
                events={{
                  click: (e) => {
                    if (e.shapes && e.shapes.length > 0) {
                      const prop = e.shapes[0]
                      // Set popup options
                      setPopupOptions({
                        ...popupOptions,
                        position: new data.Position(
                          prop.geometry.coordinates[0],
                          prop.geometry.coordinates[1]
                        ),
                        pixelOffset: [0, -40],
                        isVisible: true,
                        closeButton: false,
                      })

                      //fill popup with data
                      if (prop.properties) {
                        const site = JSON.parse(prop.properties.popUpProp)
                        handleSiteClick(site)
                        setPopupSelectedSite(site.id)
                      }
                    }
                  },
                }}
                type="SymbolLayer"
              />
              {memoizedMarkerRender}
            </AzureMapDataSourceProvider>
            <AzureMapPopup
              isVisible={popupOptions.isVisible}
              options={popupOptions}
              sx={{
                borderRadius: 30,
              }}
              popupContent={
                <div className={styles.popup}>
                  <h3 className={styles.popupGroupName}>
                    {popupProperties.name}
                  </h3>
                  <p className={styles.popupGroupAddress}>
                    {popupProperties.address}
                  </p>
                  {isLoadingBackground ? (
                    <div className={styles.popupLoading}>
                      <Spinner
                        color="#ea5449"
                        style={styles.popupLoadingSpinner}
                      />
                    </div>
                  ) : (
                    <div className={styles.popupGroupInfoWrapper}>
                      <div className={styles.popupGroupInfo}>
                        <div className={styles.popupGroupInfoName}>Solar:</div>
                        <div className={styles.popupGroupInfoValue}>
                          {popupProperties?.solar?.size} kW
                        </div>
                        {popupProperties?.solar?.status && (
                          <Chip
                            type={popupProperties?.solar?.status}
                            value={popupProperties?.solar?.status}
                          />
                        )}
                      </div>
                      <div className={styles.popupGroupInfo}>
                        <div className={styles.popupGroupInfoName}>
                          Battery:
                        </div>
                        {(popupProperties?.battery?.size && (
                          <>
                            <div className={styles.popupGroupInfoValue}>
                              {popupProperties?.battery?.size} kWh
                            </div>
                            {popupProperties.battery.status && (
                              <Chip
                                type={popupProperties?.battery?.status}
                                value={popupProperties?.battery?.status}
                              />
                            )}
                          </>
                        )) || (
                          <div className={styles.popupGroupInfoValue}>
                            Not installed
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              }
            />
          </AzureMap>
        </div>
      </>
    </AzureMapsProvider>
  ) : (
    <Box>
      <Spinner color="#ea5449" />
      <Skeleton
        width={70}
        height={30}
        sx={{
          marginBottom: 3,
        }}
        className={styles.header}
      ></Skeleton>
      <Skeleton
        variant="rounded"
        sx={{
          borderRadius: 14,
        }}
        className={styles.mapSkeleton}
      ></Skeleton>
    </Box>
  )
}

export default Map
