import React from 'react'
import classnames from 'classnames'

// import user from '../../assets/img/user.svg'

import styles from './PersonalInfo.module.scss'

const PersonalInfo = ({ userInfo }) => {
  return (
    <>
      <div className={styles.wrapper}>
        {/* <div className={styles.imgWrapper}>
          <img
            src={user}
            className={styles.img}
            alt="user profile"
          />
        </div> */}
        <div className={styles.description}>
          {Object.keys(userInfo).map((key) => {
            const value = userInfo[key].value
            const label = userInfo[key].label

            return (
              <div
                className={classnames(styles.descriptionRow, {
                  [styles.descriptionRowName]: key === 'name',
                })}
                key={label}
              >
                <h4 className={styles.descriptionRowHeader}>{label}:</h4>
                <span className={styles.descriptionRowValue}>{value}</span>
              </div>
            )
          })}
          <a
            className={styles.changePass}
            href="https://account.live.com/ResetPassword.aspx"
          >
            Reset Password
          </a>
        </div>
      </div>
    </>
  )
}

export default PersonalInfo
