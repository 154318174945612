import { useState, useEffect, useMemo } from 'react'
import { useIsAuthenticated } from '@azure/msal-react'

import { useGroup } from '../../context/Group.jsx'
import weatherTypes from '../../utils/constants/weatherTypes'
import weatherStatuses from '../../utils/constants/weatherStatuses.js'
import { get } from '../../utils/api'
import { mapAPiKey } from '../../utils/constants/variables'

import locationIcon from '../../assets/img/location-icon.svg'
import sunIcon from '../../assets/img/weather/sun.svg'
import cloudIcon from '../../assets/img/weather/cloud.svg'
import partlyCloudIcon from '../../assets/img/weather/partly_cloudy.svg'
import rainIcon from '../../assets/img/weather/rain.svg'
import snowIcon from '../../assets/img/weather/snow.svg'
import thunderstormIcon from '../../assets/img/weather/thunderstorm.svg'

import weatherStyles from './Weather.module.scss'

const defaultWeather = {
  phrase: 'Sunny',
  temperature: {
    value: null,
    unit: 'F',
  },
}

const Weather = () => {
  const [weather, setWeather] = useState(defaultWeather)
  const [location, setLocation] = useState('')
  const { mapData, groupData } = useGroup()

  const isAuth = useIsAuthenticated()
  const coords = useMemo(() => {
    if (mapData.length > 0 && groupData.activeGroup) {
      const [currentGroup] = mapData.filter(
        (group) => group?.name === groupData?.activeGroup?.company_name
      )
      setLocation(currentGroup.address)
      return `${currentGroup.latitude},${currentGroup.longitude}`
    }
  }, [mapData, groupData])

  const weatherType = weatherStatuses(weather.phrase)
  const isSunWeather = weatherType === weatherTypes.sun
  const isCloudWeather = weatherType === weatherTypes.cloud
  const isPartlyCloudWeather = weatherType === weatherTypes.partlyCloudy
  const isRainyWeather = weatherType === weatherTypes.rain
  const isSnowWeather = weatherType === weatherTypes.snow
  const icon = isSunWeather
    ? sunIcon
    : isCloudWeather
    ? cloudIcon
    : isPartlyCloudWeather
    ? partlyCloudIcon
    : isRainyWeather
    ? rainIcon
    : isSnowWeather
    ? snowIcon
    : thunderstormIcon

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await get(
          `https://atlas.microsoft.com/weather/currentConditions/json?api-version=1.1&query=${coords}&subscription-key=${mapAPiKey}&language='en-AU'`
        )
        const { results } = data

        setWeather(results[0])
      } catch (e) {
        console.log('error', e)
      }
    }

    if (isAuth) {
      fetchData()
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth])

  return (
    <div className={weatherStyles.container}>
      <div className={weatherStyles.locationContainer}>
        <img
          src={locationIcon}
          alt="location"
        />
        <span className={weatherStyles.text}>{location}</span>
      </div>
      <div className={weatherStyles.weatherContainer}>
        <img
          src={icon}
          alt="weather"
        />
        <div className={weatherStyles.weatherTextContainer}>
          <h6 className={weatherStyles.weatherDegrees}>
            {weather.temperature.value} °{weather.temperature.unit}
          </h6>
          <span className={weatherStyles.text}>{weather.phrase}</span>
        </div>
      </div>
    </div>
  )
}

export default Weather
