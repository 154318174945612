import { solarPanelStatuses } from '../../utils/constants/solarPanelStatuses'
import { statusesColors } from '../../utils/constants/statusesColors'

const FlexPanel = ({ status }) => {
  const isPositive = status === solarPanelStatuses.optimal
  const isSubOptimal = status === solarPanelStatuses.subOptimal
  const isOnline = status === solarPanelStatuses.online
  const isError = status === solarPanelStatuses.offline
  /* eslint-disable indent */
  const fillColor = isOnline
    ? statusesColors.online
    : isPositive
    ? statusesColors.optimal
    : isSubOptimal
    ? statusesColors.subOptimal
    : statusesColors.error

  return (
    <svg
      width="219"
      height="135"
      viewBox="0 0 219 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M201.901 106.1H17.1006V13C17.1006 5.8 22.9006 0 30.1006 0H189.001C196.201 0 202.001 5.8 202.001 13V106.1H201.901Z"
        fill="#E4EAF0"
      />
      <path
        d="M177.399 98.6002H41.5994C35.1994 98.6002 29.8994 93.4001 29.8994 86.9001V22.1002C29.8994 15.7002 35.0994 10.4001 41.5994 10.4001H177.399C183.799 10.4001 189.099 15.6002 189.099 22.1002V86.9001C189.099 93.4001 183.899 98.6002 177.399 98.6002Z"
        fill="white"
        fillOpacity="0.5"
      />
      <path
        d="M41.5994 98.6002C35.1994 98.6002 29.8994 93.4001 29.8994 86.9001V22.1002C29.8994 15.7002 35.0994 10.4001 41.5994 10.4001H177.399C183.799 10.4001 189.099 15.6002 189.099 22.1002L41.5994 98.6002Z"
        fill="white"
      />
      <path
        d="M206.1 134.1H12.9C5.8 134.1 0 128.3 0 121.2V119.9C0 112.8 5.8 107 12.9 107H206.1C213.2 107 219 112.8 219 119.9V121.2C219 128.4 213.2 134.1 206.1 134.1Z"
        fill="#BDCCD8"
      />
      <path
        d="M218.8 119.8H0.200001C0.100001 119.8 0 119.7 0 119.6V118.9C0 111.8 5.8 106 12.9 106H206.1C213.2 106 219 111.8 219 118.9V119.6C219 119.7 218.9 119.8 218.8 119.8Z"
        fill="#E4EAF0"
      />
      <path
        d="M137.1 123.8H82.0003C80.0003 123.8 78.3003 122.1 78.3003 120.1C78.3003 118.1 80.0003 116.4 82.0003 116.4H137.1C139.1 116.4 140.8 118.1 140.8 120.1C140.8 122.1 139.1 123.8 137.1 123.8Z"
        fill="white"
      />
      {!isError && (
        <path
          d="M130.192 72.8135L130.185 72.8085C124.25 68.105 116.956 65.4164 109.156 65.2484C101.356 65.0805 93.8937 67.4328 87.6222 71.9662C85.4587 73.5253 85.0367 76.6751 87.0376 78.657C88.6341 80.2385 91.1909 80.4063 93.1219 79.0673L93.1317 79.0605L93.1413 79.0533C97.6566 75.6987 103.305 73.9395 108.953 74.0993C114.612 74.2596 120.1 76.1815 124.451 79.6933L124.472 79.71L124.493 79.7252C126.37 81.0261 128.928 81.0989 130.564 79.4929C132.582 77.6778 132.317 74.5362 130.192 72.8135Z"
          fill={fillColor}
          stroke={fillColor}
        />
      )}
      {!isError && (
        <path
          d="M73.8941 63.6928L73.9213 63.7228L73.9519 63.7496C75.6477 65.2428 78.2373 65.5373 79.9097 63.9113C88.0701 57.2934 98.5139 53.7393 109.123 53.8996C119.915 54.2247 130.04 58.1117 137.874 65.0635L137.876 65.0654C139.647 66.6254 142.224 66.6254 143.995 65.0654L143.999 65.0625C145.982 63.2942 145.982 60.3253 143.999 58.557L143.997 58.555C134.57 50.2178 122.282 45.381 109.486 45.213C96.69 44.8806 84.2366 49.2172 74.3106 57.2158C72.3026 58.8092 72.1373 61.7591 73.8941 63.6928Z"
          fill={fillColor}
          stroke={fillColor}
        />
      )}
      {isPositive && (
        <path
          d="M60.9439 48.7556L60.9567 48.7698L60.9704 48.7833C62.7179 50.5145 65.2952 50.7214 67.2582 49.1657L67.2686 49.1576L67.2786 49.149C79.0947 39.0698 94.36 33.7031 109.955 34.0283C125.552 34.3536 140.323 40.2079 151.646 50.6115H151.646L151.657 50.6218C153.429 52.1817 156.005 52.1817 157.777 50.6218L157.78 50.6187C159.762 48.8522 159.764 45.8871 157.786 44.1182C144.869 32.1562 127.928 25.3451 110.15 25.0128L110.15 25.0128C92.209 24.6806 74.937 30.8253 61.3536 42.2841C59.3521 43.8789 59.1891 46.8242 60.9439 48.7556Z"
          fill={fillColor}
          stroke={fillColor}
        />
      )}
      {isError && (
        <>
          <path
            d="M91 38L129 71.8919"
            stroke="#EA5449"
            strokeWidth="12"
            strokeLinecap="round"
          />
          <path
            d="M129 38L91 71.8919"
            stroke="#EA5449"
            strokeWidth="12"
            strokeLinecap="round"
          />
        </>
      )}
    </svg>
  )
}

export default FlexPanel
