import SvgIcon from '@mui/material/SvgIcon'

const HomeIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 15 14"
    >
      <path
        d="M12.5497 13.3201H10.0304V10.3535C10.0304 9.68307 9.47362 9.1514 8.80276 9.1514H7.19692C6.52605 9.1514 5.9693 9.68307 5.9693 10.3535V13.3201H3.44996C3.23467 13.3201 3.07174 13.1517 3.07174 12.959V6.10042L7.72921 1.56596C7.79973 1.49743 7.8968 1.4577 7.99948 1.4577C8.10215 1.4577 8.19924 1.49744 8.26976 1.56596L12.9279 6.10061V12.959C12.9279 13.1517 12.765 13.3201 12.5497 13.3201ZM13.7773 6.92748L14.6607 7.78745C14.8278 7.95011 15.0964 7.94804 15.2609 7.78279C15.4257 7.61724 15.4236 7.35068 15.2562 7.18772L8.86498 0.965968L8.86495 0.965938C8.63413 0.741642 8.32259 0.616668 7.99948 0.616668C7.67639 0.616668 7.36507 0.741403 7.13425 0.965709L7.13422 0.965741L0.743438 7.18773C0.576041 7.35071 0.573936 7.61726 0.738766 7.78281C0.903288 7.94805 1.17188 7.9501 1.33896 7.78743L2.22234 6.92738V12.959C2.22234 13.6294 2.7791 14.1611 3.44996 14.1611H12.5497C13.2206 14.1611 13.7773 13.6294 13.7773 12.959V6.92748ZM9.18098 13.3201H6.81869V10.3535C6.81869 10.1608 6.98163 9.99244 7.19692 9.99244H8.80276C9.01804 9.99244 9.18098 10.1608 9.18098 10.3535V13.3201Z"
        fill={props.color}
        stroke={props.color}
        strokeWidth="0.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default HomeIcon
