import Box from '@mui/material/Box'
import classnames from 'classnames'

import BatteryCharge from './BatteryCharge.jsx'
import StatusData from '../StatusData/StatusData.jsx'

import { useGroup } from '../../context/Group.jsx'

import cardStyles from '../../layouts/Card/Card.module.scss'

const defaultBatteryData = {
  status: 'error',
  soc: 0,
  size: 0,
  duration: 0,
}

const BatteryStatus = () => {
  const { groupHelpers } = useGroup()
  const batteryData = groupHelpers.getActiveGroupData(
    'battery',
    defaultBatteryData
  )
  const normBattaryData = {
    soc: batteryData.soc,
    status: batteryData.status,
    other: {
      size: batteryData.size + ' kW',
      duration:
        batteryData.duration > 0
          ? parseFloat(batteryData.duration.toFixed(1)) + ' hrs'
          : 0,
    },
  }
  return (
    <Box
      className={classnames(
        cardStyles.card,
        cardStyles.cardOperations,
        cardStyles.battery
      )}
    >
      <div className={cardStyles.innerTitle}>Battery</div>
      <div className={cardStyles.imgWrapper}>
        <BatteryCharge
          value={normBattaryData.soc}
          status={normBattaryData.status}
        />
      </div>
      <StatusData
        status={normBattaryData.status}
        list={normBattaryData.other}
      />
    </Box>
  )
}

export default BatteryStatus
