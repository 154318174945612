export const dropdown = {
  overflow: 'visible',
  filter: 'drop-shadow(4px 4px 20px #DBE2E9)',
  mt: 1.5,
  px: '1px',
  maxWidth: 250,
  width: '100%',
  borderRadius: '14px',
}

export const dropdownItem = {
  font: '400 14px/24px "Inter"',
  color: '#001B40',
  bgcolor: '#FFFFFF',
  letterSpacing: '-0.02em',
  '&:focus': {
    bgcolor: '#FFFFFF',
  },
  '&:hover': {
    bgcolor: '#F3F7F9',
  },
}
