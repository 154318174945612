import CircularProgress from '@mui/material/CircularProgress'
import classnames from 'classnames'

import styles from './Spinner.module.scss'

const Spinner = ({ color, style = {} }) => {
  return (
    <div className={classnames(style, styles.spinner)}>
      <CircularProgress sx={{ color: color ? color : '#57b0f4' }} />
    </div>
  )
}

export default Spinner
