import React, { useEffect, useRef } from 'react'
import { Chart, registerables } from 'chart.js/auto'
import { useGroup } from '../../../context/Group.jsx'
import * as dayjs from 'dayjs'
var isToday = require('dayjs/plugin/isToday')

dayjs.extend(isToday)

Chart.register(...registerables)

const defaultChartData = {
  dailyData: {
    newData: [
      {
        date: new Date(),
        grid_export_kw: 0.0,
				grid_import_kw: 0.0,
				pv_generation_potential_kw: 0.0,
				load_kw: 0.0,
				bess_charge_discharge_kw: 0.0,
				bess_soc_kw: 0.0
      },
    ],
  },
  yearlyData: {},
}
function EnergyUsageChart({ period }) {
  const chartRef = useRef(null)
  const { groupHelpers } = useGroup()
  const chartData = groupHelpers.getActiveGroupData(
    'energy_usage',
    defaultChartData
  )

  const normGeneration = chartData?.dailyData?.newData.map(
		({ date, grid_export_kw, grid_import_kw, pv_generation_potential_kw, load_kw, bess_charge_discharge_kw, bess_soc_kw }) => {
			return {
				grid_export_kw: parseFloat(grid_export_kw.toFixed(2)),
				grid_import_kw: parseFloat(grid_import_kw.toFixed(2)),
				pv_generation_potential_kw: parseFloat(pv_generation_potential_kw.toFixed(2)),
				load_kw: parseFloat(load_kw.toFixed(2)),
				bess_charge_discharge_kw: parseFloat(bess_charge_discharge_kw.toFixed(2)),
				bess_soc_kw: parseFloat((bess_soc_kw).toFixed(2)),
				date: new Date(date),
			}
		}
	)
  const normDailyData = (normGeneration || []).filter((item) => {
    return dayjs(item.date).isToday()
  })

  const normWeeklyData = (normGeneration || []).filter((item) => {
    return item.date.getMinutes() === 0
  })

  useEffect(() => {
    let maxY = -Infinity; // Initialize with negative infinity to ensure any value will be greater
    let minY = Infinity; // Initialize with positive infinity to ensure any value will be smaller
    let chart
    //Rendering chart
    const renderChart = (test) => {
      const ctx = chartRef.current.getContext('2d');
      chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: test,
          datasets: [
            {
              label: 'SOC',
              data: bess_soc_kw,
              backgroundColor: 'rgba(216, 191, 216, 0.5)', 
              borderColor: 'rgb(216, 191, 216)',
              borderWidth: 1, 
              fill: false,
              tension: 0.2,
              pointRadius: 0,
              yAxisID: 'y-axis-2', 
            },
            {
							label: 'Grid',
							data: grid_kw,
							backgroundColor: 'rgba(234, 84, 73, 0.3)', 
							borderColor: 'rgb(234, 84, 73)',
							borderWidth: 1, 
							fill: true,
							tension: 0.2,
							pointRadius: 0,
              
					},
						{
							label: 'Load',
							data: load_kw,
							backgroundColor: 'rgba(173, 216, 230, 0.5)', 
							borderColor: 'rgb(173, 216, 230)',
							borderWidth: 1, 
							fill: true,
							tension: 0.2,
							pointRadius: 0
						},
						{
							label: 'Solar',
							data: pv_generation_potential_kw,
							backgroundColor: 'rgba(255, 255, 153, 0.5)',
							borderColor: 'rgb(255, 255, 153)',
							borderWidth: 1,
							fill: true,
							tension: 0.2,
							pointRadius: 0
						},
						{
								label: 'Battery',
								data: bess_charge_discharge_kw,
								backgroundColor: 'rgba(255, 204, 153, 0.5)', 
								borderColor: 'rgb(255, 204, 153)',
								borderWidth: 1, // Reduced line thickness
								fill: true,
								tension: 0.2,	
								pointRadius: 0
						},
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                filter: function (item) {
                  if (
                    item.text === 'Increased Demand due to Charge' ||
                    item.text === 'Decreased Demand due to Discharge' ||
                    item.text === 'Decreased Solar Generation due to Charge' ||
                    item.text === 'Battery Charge Solar'
                  ) {
                    return false;
                  } else {
                    return item;
                  }
                },
              },
            },
            hover: {
              mode: 'nearest',
              intersect: true,
            },
          },
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            x: {
              ticks: {
                maxTicksLimit: 8,
              },
              grid: {
                display: false,
              },
            },
            y: {
              type: 'linear',
              position: 'left',
              grid: {
                display: false,
              },
              title: {
                display: true,
                text: 'kW',
                color: 'black',
              },
              max: maxY + 20, 
              min: minY - 20, 
              ticks: {
                stepSize: 10
              }
            },
            'y-axis-2': { 
              type: 'linear',
              position: 'right',
              grid: {
                display: false,
                drawOnChartArea: false, 
              },
              title: {
                display: true,
                text: '%', 
                color: 'rgb(216, 191, 216)',
              },
              max: 100 + 10, // Max %
              min: 0 - 10, // Min %
              ticks: {
		callback: function(value) {
                  return value + '%';
		},
                stepSize: 10
              }
            },
          },
        },
      });
    };
    
    let grid_kw= []
		let pv_generation_potential_kw = [];
		let load_kw = [];
		let bess_charge_discharge_kw = [];
		let bess_soc_kw = [];

    try {
        if (period === 'day') {
          const labels = [];
          for (let i = 0; i <= 288; i++) { // 24 hours * 60 minutes / 5 minutes interval
              const hours = Math.floor(i * 5 / 60);
              const minutes = (i * 5) % 60;
              const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
              labels.push(formattedTime);
          }
          
          for (let i = 0; i < normDailyData.length; i++) {
            let grid = normDailyData[i]['grid_import_kw'] - normDailyData[i]['grid_export_kw']

            grid_kw.push(grid);
            pv_generation_potential_kw.push(normDailyData[i]['pv_generation_potential_kw']);
            load_kw.push(normDailyData[i]['load_kw']);
            bess_charge_discharge_kw.push(normDailyData[i]['bess_charge_discharge_kw']);
            bess_soc_kw.push(normDailyData[i]['bess_soc_kw']);

            maxY = Math.max(
              maxY, 
              grid,
              normDailyData[i]['pv_generation_potential_kw'],
              normDailyData[i]['load_kw'],
              normDailyData[i]['bess_charge_discharge_kw'],
            )

            minY = Math.min(  
              minY, 
              grid,
              normDailyData[i]['pv_generation_potential_kw'],
              normDailyData[i]['load_kw'],
              normDailyData[i]['bess_charge_discharge_kw'],
            )

            maxY = Math.ceil(maxY / 20) * 20;
            minY = Math.ceil(minY / 20) * 20;
          }

          renderChart(
            labels
          )
        }
      //Setting arrays for 'Week' toggle
      else if (period === 'week') {
        const labels = [];
        const now = new Date();
        const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0, 0); // Start 7 days ago at 00:00

        for (let i = 0; i < 7 * 24; i++) { // 7 days * 24 hours
            const time = new Date(startTime.getTime() + (i * 60 * 60 * 1000)); // Add i hours to startTime
            labels.push(time.toLocaleString('en-US', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false }));
        }
        
				for (let i = 0; i < normWeeklyData.length; i++) {
          let grid = normWeeklyData[i]['grid_import_kw'] - normWeeklyData[i]['grid_export_kw']

          grid_kw.push(grid);
					pv_generation_potential_kw.push(normWeeklyData[i]['pv_generation_potential_kw']);
					load_kw.push(normWeeklyData[i]['load_kw']);
					bess_charge_discharge_kw.push(normWeeklyData[i]['bess_charge_discharge_kw']);
					bess_soc_kw.push(normWeeklyData[i]['bess_soc_kw']);

          maxY = Math.max(
            maxY, 
            grid,
            normWeeklyData[i]['pv_generation_potential_kw'],
            normWeeklyData[i]['load_kw'],
            normWeeklyData[i]['bess_charge_discharge_kw'],
          )

          minY = Math.min(  
            minY, 
            grid,
            normWeeklyData[i]['pv_generation_potential_kw'],
            normWeeklyData[i]['load_kw'],
            normWeeklyData[i]['bess_charge_discharge_kw'],
          )

          maxY = Math.ceil(maxY / 20) * 20;
          minY = Math.ceil(minY / 20) * 20;
        }

        renderChart(
          labels,
        )
      }
      //Setting arrays for period 'Day' toggle
      return () => {
        chart.destroy()
      }
    } catch (err) {
      console.log('STU', err)
    }
  }, [period, normDailyData, normWeeklyData])
  return <canvas ref={chartRef} />
}
/* eslint-enable */
export default EnergyUsageChart
