import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import BatteryStatus from '../../components/BatteryStatus/BatteryStatus.jsx'
import SolarPanelStatus from '../../components/SolarPanelStatus/SolarPanelStatus.jsx'
import FlexStatus from '../../components/FlexStatus/FlexStatus.jsx'
import GridPurchases from '../../components/GridPurchases/GridPurchases.jsx'
import { useGroup } from '../../context/Group.jsx'
import { isJuniorOrSeniorCompany } from '../../utils/common.js'

//TODO static data
const flexData = {
  size: '15 kW',
}
const gridPurchasesData = {
  Energy_Rate: {
    name: 'Energy Rate',
    // percent: 54,
    amount: 28,
  },
  Network_Energy_Rate: {
    name: 'Network Energy Rate',
    // percent: 46,
    amount: 6,
  },
  Environmental_Rate: {
    name: 'Environmental Rate',
    amount: 14,
  },
  Regulatory_and_other_Rate: {
    name: 'Regulatory and other Rate',
    amount: 8,
  },
}

const Operations = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('xsSm'))
  const { groupData } = useGroup()
  const companyName = groupData.activeGroup ? groupData.activeGroup.company_name : ''

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={isSmall ? 0 : 2}
    >
      <Grid
        item
        xs={12}
        xsSm={6}
        sm={6}
        lg={!isJuniorOrSeniorCompany(companyName) ? 3 : 4}
      >
        <BatteryStatus />
      </Grid>
      <Grid
        item
        xs={12}
        xsSm={6}
        sm={6}
        lg={!isJuniorOrSeniorCompany(companyName) ? 3 : 4}
      >
        <SolarPanelStatus />
      </Grid>
      {
        !isJuniorOrSeniorCompany(companyName) ? <Grid
          item
          xs={12}
          xsSm={6}
          sm={6}
          lg={3}
        >
          <FlexStatus
            status="sub-optimal"
            data={flexData}
          />
        </Grid> : ''
      }
      <Grid
        item
        xs={12}
        xsSm={6}
        sm={6}
        lg={!isJuniorOrSeniorCompany(companyName) ? 3 : 4}
      >
        <GridPurchases data={gridPurchasesData} />
      </Grid>
    </Grid>
  )
}

export default Operations
