import { batteryStatuses } from '../../utils/constants/batteryStatuses'

import styles from './BatteryCharge.module.scss'

const BatteryCharge = ({ value, status }) => {
  const isPositive = status === batteryStatuses.charging
  const isIdle = status === batteryStatuses.idle
  const isError = status === batteryStatuses.error
  const fillColor = isPositive
    ? '#57B0F4'
    : isError
    ? '#F18C2E'
    : isIdle
    ? '#F18C2E'
    : '#62778E'
  const valuesFixed = value < 6 ? 6 : value
  const minValue = 0.8
  const maxValue = 308
  const fillValue = ((maxValue - minValue) / 100) * valuesFixed
  const rightBorderRadiusPos = value > 95 ? 287.486 : fillValue
  const rightTopBorder =
    value <= 95 ? `0.449951L${fillValue}` : '0.44995C299.094'

  return (
    <div className={styles.battery}>
      <svg
        width="100%"
        viewBox="0 0 333 150"
        preserveAspectRatio="xMidYMid meet"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.img}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M308.4 46.45L308.4 107.45L319.908 107.45C326.793 107.45 332.4 102.245 332.4 95.8526L332.4 58.0473C332.4 51.655 326.793 46.45 319.908 46.45L308.4 46.45ZM0.400024 23.8887L0.400019 130.011C0.400018 140.707 9.77554 149.45 21.2457 149.45L286.554 149.45C298.024 149.45 307.4 140.8 307.4 130.011L307.4 23.8887C307.4 13.1927 298.124 4.44995 286.554 4.44995L21.2457 4.44994C9.77554 4.44994 0.400024 13.0997 0.400024 23.8887Z"
          fill="#BDCCD8"
        />
        <path
          d="M308 100.45L308 42.75L319.7 42.75C326.7 42.75 332.4 48.45 332.4 55.45L332.4 87.75C332.4 94.75 326.7 100.45 319.7 100.45L308 100.45Z"
          fill="#E4EAF0"
        />
        <path
          d="M0.400019 121.594L0.400024 21.3059C0.400024 9.73033 9.80609 0.449938 21.3136 0.449939L287.486 0.44995C299.094 0.449951 308.4 9.83014 308.4 21.3059L308.4 121.594C308.4 133.17 298.994 142.45 287.486 142.45L21.3136 142.45C9.80609 142.45 0.400019 133.07 0.400019 121.594Z"
          fill="#E4EAF0"
        />
        {/* <path
          d={`M0.800013    121.75L2.9604e-05 21.35C3.01111e-05 9.74995 10.2    0.449945 20.9 0.449945L${fillValue} 0.449951L${fillValue} 142.75L21.8 142.75C10.2    142.75 0.800013    133.35 0.800013    121.85Z`}
          fill={fillColor}
        /> */}
        <path
          d={`M0.400019 121.594L0.400024 21.3059C0.400024 9.73033 9.80609 0.449938 21.3136 0.449939L${rightBorderRadiusPos} ${rightTopBorder} 0.449951 ${fillValue} 9.83014 ${fillValue} 21.3059L${fillValue} 121.594C${fillValue} 133.17 ${fillValue} 142.45 ${rightBorderRadiusPos} 142.45L21.3136 142.45C9.80609 142.45 0.400019 133.07 0.400019 121.594Z`}
          fill={fillColor}
        />
      </svg>

      <div className={styles.batteryValue}>{value}%</div>
    </div>
  )
}

//0.44995C299.094

export default BatteryCharge
