import { useState } from 'react'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Card from '../../layouts/Card/Card.jsx'
import EnergyUsage from '../../components/EnergyUsage/EnergyUsage.jsx'
import Consumption from '../../components/Consumption/Consumption.jsx'
import TotalSavings from '../../components/TotalSavings/TotalSavings.jsx'
import EmissionReduced from '../../components/EmissionReduced/EmissionReduced.jsx'

import cardBg from '../../assets/img/performance-card-bg.svg'

import styles from './Performance.module.scss'
import { useGroup } from '../../context/Group.jsx'
import { isJuniorOrSeniorCompany } from '../../utils/common.js'

const Performance = () => {
  const [isLandscapeChart, setChartView] = useState(false)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { groupData } = useGroup()
  const companyName = groupData.activeGroup ? groupData.activeGroup.company_name : ''

  const changeChartView = () => {
    setChartView(!isLandscapeChart)
    isLandscapeChart
      ? document.body.classList.remove('hidden')
      : document.body.classList.add('hidden')
  }

  return (
    <Grid
      container
      direction="row"
      rowSpacing={isSmall ? 2 : 5}
      columnSpacing={isSmall ? 0 : 4}
    >
      <Grid
        item
        xs={12}
      >
        <Card
          orientation="start"
          title="Performance"
        >
          <EnergyUsage
            isLandscapeChart={isLandscapeChart}
            changeChartView={changeChartView}
          />
        </Card>
        <button
          className={styles.btn}
          onClick={changeChartView}
        >
          Landscape Mode
        </button>
      </Grid>
      <Grid
        item
        xs={12}
        lg={!isJuniorOrSeniorCompany(companyName) ? 4 : 6}
      >
        <Card
          title="Consumption"
          singleCard
        >
          <Consumption />
        </Card>
      </Grid>
      {
        !isJuniorOrSeniorCompany(companyName) ? 
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
        >
          <Card
            title="Total Savings"
            singleCard
            background={cardBg}
          >
            <TotalSavings />
          </Card>
        </Grid> : ''
      }
      <Grid
        item
        xs={12}
        md={6}
        lg={!isJuniorOrSeniorCompany(companyName) ? 4 : 6}
      >
        <Card
          title="Emissions Reduced"
          singleCard
          background={cardBg}
        >
          <EmissionReduced />
        </Card>
      </Grid>
    </Grid>
  )
}

export default Performance
