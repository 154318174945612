import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import { useGroup } from '../../context/Group.jsx'

import GroupList from './GroupList.jsx'
import UserProfile from './UserProfile.jsx'

import groupStyles from './Group.module.scss'

const Group = () => {
  const { groupData } = useGroup()

  return (
    <div className={groupStyles.group}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          {groupData.isLoading ? (
            <Skeleton
              width={150}
              height={40}
            ></Skeleton>
          ) : (
            <GroupList />
          )}
        </Grid>
        <Grid item>
          <UserProfile />
        </Grid>
      </Grid>
    </div>
  )
}

export default Group
