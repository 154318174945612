import { createContext, useContext, useState, useMemo } from 'react'
import { get } from '../utils/api'

const Group = createContext()

//create custom hook to check whether we're inside provider
export function useGroup() {
  const context = useContext(Group)

  if (!context) {
    throw new Error('useGroup must be used within an GroupProvider')
  }

  return context
}

export function GroupProvider(props) {
  const [groupsList, setGroupsList] = useState([])
  const [activeGroup, setActiveGroup] = useState(null)
  const [mapData, setMapData] = useState([])
  const [err, setErr] = useState('')
  const [isLoading, setLoading] = useState(true)
  const [isLoadingBackground, setLoadingBackground] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const value = useMemo(() => {
    async function getUserGroups(accessToken, idToken) {
      setLoading(true)
      try {
        const { data } = await get('/user', {
          headers: {
            authorization: `Bearer ${accessToken}`,
            usertoken: idToken,
          },
        })

        const newGroupNamesList = data.map(
          ({ company_name, project_info_id, name, contact_company, phone_number }) => ({
            name: company_name,
            id: project_info_id,
            contactName: name,
            contactCompany: contact_company,
            contactPhone: phone_number 
          })
        )
        const generatedMapData = data.map(
          ({
            company_name,
            customer_address,
            project_info_id,
            Latitude,
            Longitude,
          }) => ({
            name: company_name,
            address: customer_address,
            id: project_info_id,
            latitude: Latitude,
            longitude: Longitude,
          })
        )
        setMapData(generatedMapData)
        setGroupsList(newGroupNamesList)
        const activeGroup = JSON.parse(localStorage.getItem('currentGroup'))
        return activeGroup ? activeGroup : newGroupNamesList[0]
      } catch (e) {
        console.log('error', e)
        setErr('Failed to load groups. Please reload the page')
      } finally {
        setLoading(false)
      }
    }

    async function getSiteMapData(group, accessToken, idToken) {
      setLoadingBackground(true) //TODO: figure out specific loading
      try {
        const { data } = await get(`/sitestatus?site_id=${group.id}`, {
          headers: {
            authorization: `Bearer ${accessToken}`,
            usertoken: idToken,
          },
        })
        let currentSiteMapData = mapData.find((site) => site.id === group.id)
        currentSiteMapData = {
          ...currentSiteMapData,
          ...data[0],
        }
        const newMapData = mapData.map((site) => {
          return site.id === group.id ? currentSiteMapData : site
        })
        setMapData(newMapData)
      } catch (e) {
        console.log('error', e)
        setErr(`Failed to load ${group.name} groups. Please reload the page`)
      } finally {
        setLoadingBackground(false)
      }
    }

    async function changeActiveGroup(group, accessToken, idToken) {
      setLoading(true)
      try {
        const { data } = await get(`/sitedata?site_id=${group.id}`, {
          headers: {
            authorization: `Bearer ${accessToken}`,
            usertoken: idToken,
          },
        })
        localStorage.setItem(
          'currentGroup',
          JSON.stringify({
            id: group.id,
            name: data.company_name,
          })
        )
        setActiveGroup(data)
      } catch (e) {
        console.log('error', e)
        setErr(`Failed to load ${group.name} groups. Please reload the page`)
      } finally {
        setLoading(false)
      }
    }

    async function exportCSV(group, accessToken, idToken) {
      try {
        setIsDownloading(true)
        const response = await get(`/export?site_id=${group}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            usertoken: idToken,
          }, 
          responseType: 'blob', 
        });

        const csvBlob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(csvBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', generateFilename());
        document.body.appendChild(link);  
        link.click();
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error('Error exporting CSV:', error);
      } finally {
        setIsDownloading(false)
      }
    };

    async function refreshActiveGroup(groupId, accessToken, idToken) {
      setLoading(true)
      try {
        const { data } = await get(`/sitedata?site_id=${groupId}`, {
          headers: {
            authorization: `Bearer ${accessToken}`,
            usertoken: idToken,
          },
        })
        setActiveGroup(data)
      } catch (e) {
        console.log('error', e)
        setErr('Failed to refresh group. Please reload the page')
      } finally {
        setLoading(false)
      }
    }

    function getActiveGroupData(field, defaultData) {
      return activeGroup && activeGroup[field]
        ? activeGroup[field]
        : defaultData
    }

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    
    const generateFilename = () => {
      const currentDate = new Date();
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(currentDate.getDate() - 7);
    
      const formattedCurrentDate = formatDate(currentDate);
      const formattedSevenDaysAgo = formatDate(sevenDaysAgo);
    
      return `${formattedSevenDaysAgo}_to_${formattedCurrentDate}_energy_usage.csv`;
    }

    return {
      groupData: {
        groupsList,
        activeGroup,
        err,
        isLoading,
        isDownloading,
        isLoadingBackground,
      },
      groupActions: {
        changeActiveGroup,
        getUserGroups,
        exportCSV,
        refreshActiveGroup,
        getSiteMapData,
      },
      groupHelpers: { getActiveGroupData },
      mapData: mapData,
    }
  }, [groupsList, activeGroup, mapData, err, isLoading, isLoadingBackground, isDownloading])
  return (
    <Group.Provider
      value={value}
      {...props}
    />
  )
}
