function getDimensionsCanvasElements(containerWidth, isSmall) {
  const defaultCanvasWidth = 550
  const isBigContainer = containerWidth >= 500
  const canvasWidth =
    isBigContainer && containerWidth - 48 >= defaultCanvasWidth
      ? defaultCanvasWidth
      : containerWidth
  const canvasHeight = isBigContainer ? 770 : 550
  const fontSize = !isSmall ? 14 : 12
  const gridImgWidth = isBigContainer ? 80 : 44
  const solarImgWidth = isBigContainer ? 122 : 66
  const demandImgWidth = isBigContainer ? 78 : 42
  const batteryImgWidth = isBigContainer ? 130 : 71
  const centerX = canvasWidth / 2
  const centerY = canvasHeight / 2
  const solarImgHeight = solarImgWidth * 0.65
  const demandImgHeight = demandImgWidth * 1.2
  const gridImgHeight = gridImgWidth * 1.375
  const batteryImgHeight = batteryImgWidth * 0.45
  const arrowsOffset = isBigContainer ? 30 : 15

  //battery Dash top point
  const imageSpacing = 24
  const blockSpacing = isBigContainer ? 24 : 16
  const rowHeight = isBigContainer ? 20 : 16
  const rowMargin = 8
  const gridOffsetMobile = isBigContainer ? 0 : rowHeight
  const gridBlockHeight =
    gridImgHeight + imageSpacing * 2 + rowHeight * 2 - gridOffsetMobile
  const batteryBlockHeight =
    batteryImgHeight +
    imageSpacing * 2 +
    (rowHeight + rowMargin) * 3 +
    blockSpacing

  //coords
  const coords = {
    solar: {
      x: 0,
      y: centerY - solarImgHeight / 2,
    },
    demand: {
      x: canvasWidth,
      y: centerY - demandImgHeight / 2,
    },
    battery: {
      x: centerX - batteryImgWidth / 2,
      y: canvasHeight - (isBigContainer ? arrowsOffset / 2 : 20),
    },
    arrows: {
      solarGrid: {
        x: solarImgWidth + arrowsOffset,
        y: centerY - solarImgHeight / 2,
        points: [
          0,
          0,
          centerX - gridImgWidth / 2 - solarImgWidth - arrowsOffset,
          0,
          centerX - gridImgWidth / 2 - solarImgWidth - arrowsOffset,
          -centerY + solarImgHeight / 2 + gridBlockHeight,
        ],
      },
      solarBattery: {
        x: solarImgWidth + arrowsOffset,
        y: centerY + solarImgHeight / 2,
        points: [
          0,
          0,
          centerX - gridImgWidth / 2 - solarImgWidth - arrowsOffset,
          0,
          centerX - gridImgWidth / 2 - solarImgWidth - arrowsOffset,
          centerY - solarImgHeight / 2 - batteryBlockHeight,
        ],
      },
      solarDemand: {
        x: solarImgWidth + arrowsOffset,
        y: centerY,
        points: [
          0,
          0,
          0,
          0,
          canvasWidth - solarImgWidth - demandImgWidth - arrowsOffset * 2,
          0,
        ],
      },
      gridBattery: {
        x: centerX,
        y: gridBlockHeight,
        points: [
          0,
          0,
          0,
          0,
          0,
          canvasHeight - gridBlockHeight - batteryBlockHeight,
        ],
      },
      gridDemand: {
        x: centerX + gridImgWidth / 2,
        y: gridBlockHeight,
        points: [
          0,
          0,
          0,
          centerY - solarImgHeight / 2 - gridBlockHeight,
          centerX - gridImgWidth / 2 - demandImgWidth - arrowsOffset,
          centerY - solarImgHeight / 2 - gridBlockHeight,
        ],
      },
      batteryDemand: {
        x: centerX + gridImgWidth / 2,
        y: canvasHeight - batteryBlockHeight,
        points: [
          0,
          0,
          0,
          -centerY + batteryBlockHeight + solarImgHeight / 2,
          centerX - gridImgWidth / 2 - demandImgWidth - arrowsOffset,
          -centerY + batteryBlockHeight + solarImgHeight / 2,
        ],
      },
      batteryGrid: {
        x: centerX,
        y: canvasHeight - batteryBlockHeight,
        points: [
          0,
          0,
          0,
          0,
          0,
          -canvasHeight + gridBlockHeight + batteryBlockHeight,
        ],
      },
    },
  }

  return {
    canvasWidth,
    canvasHeight,
    centerX,
    centerY,
    arrowsOffset,
    fontSize,
    isBigContainer,
    solarImgWidth,
    solarImgHeight,
    demandImgWidth,
    demandImgHeight,
    batteryImgWidth,
    batteryImgHeight,
    gridImgWidth,
    gridImgHeight,
    coords,
  }
}

export { getDimensionsCanvasElements }
