import { solarPanelStatuses } from '../../utils/constants/solarPanelStatuses'
import { statusesColors } from '../../utils/constants/statusesColors'

const SolarPanel = ({ status }) => {
  const isPositive = status === solarPanelStatuses.optimal
  const isSubOptimal = status === solarPanelStatuses.subOptimal
  const isOnline = status === solarPanelStatuses.online
  /* eslint-disable indent */
  const fillColor = isOnline
    ? statusesColors.online
    : isPositive
    ? statusesColors.optimal
    : isSubOptimal
    ? statusesColors.subOptimal
    : statusesColors.error

  return (
    <svg
      width="100%"
      viewBox="0 0 163 105"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M141.489 89.0281H9.01901C4.79329 89.0281 1.97615 85.4801 2.74446 81.078L15.1015 7.95012C15.8698 3.54799 19.9034 0 24.1291 0H156.599C160.825 0 163.642 3.54799 162.873 7.95012L150.516 81.078C149.748 85.4801 145.714 89.0281 141.489 89.0281Z"
        fill="#BDCCD8"
      />
      <path
        d="M31.75 105.001C28.9352 105.001 26.7095 102.71 26.7095 99.9602V88.8317H36.8561V99.9602C36.8561 102.71 34.5649 105.001 31.75 105.001Z"
        fill="#BDCCD8"
      />
      <path
        d="M120.909 105.001C118.094 105.001 115.868 102.71 115.868 99.9602V88.8317H126.015V99.9602C125.949 102.71 123.658 105.001 120.909 105.001Z"
        fill="#BDCCD8"
      />
      <path
        d="M53.7448 30.3088L48.7697 58.5229H3.79736L8.51061 30.3088H53.7448Z"
        fill={fillColor}
      />
      <path
        d="M105.918 30.3088L100.943 58.5229H51.4536L56.4287 30.3088H105.918Z"
        fill={fillColor}
      />
      <path
        d="M148.861 58.5229H103.692L108.667 30.3088H153.574L154.033 27.5594H109.126L113.97 0H111.22L106.376 27.5594H56.8871L61.7313 0H58.9819L54.2032 27.5594H8.96907L8.51084 30.3088H53.6795L48.7044 58.5229H3.79759L3.33936 61.2723H48.2462L43.402 88.8317H46.1514L50.9956 61.2723H100.485L95.6405 88.8317H98.39L103.234 61.2723H148.468L148.861 58.5229ZM51.4538 58.5229L56.4289 30.3088H105.918L100.943 58.5229H51.4538Z"
        fill="white"
      />
      <path
        d="M145.129 80.8453C144.409 85.2313 140.416 88.7662 136.292 88.7662H98.3242L103.168 61.2068H148.403L145.129 80.8453Z"
        fill={fillColor}
      />
      <path
        d="M148.861 58.5229H103.692L108.667 30.3088H153.574L148.861 58.5229Z"
        fill={fillColor}
      />
      <path
        d="M154.033 27.5594H109.126L113.97 0H151.087C155.211 0 158.026 3.53494 157.306 7.92089L154.033 27.5594Z"
        fill={fillColor}
      />
      <path
        d="M111.286 0L106.376 27.5594H56.8872L61.7968 0H111.286Z"
        fill={fillColor}
      />
      <path
        d="M59.0471 0L54.2029 27.5594H8.96875L12.2418 7.92089C12.9619 3.53494 16.9551 0 21.0792 0H59.0471Z"
        fill={fillColor}
      />
      <path
        d="M100.485 61.2068L95.6405 88.7662H46.1514L50.9956 61.2068H100.485Z"
        fill={fillColor}
      />
      <path
        d="M48.2461 61.2068L43.402 88.7662H6.2851C2.16101 88.7662 -0.653858 85.2313 0.131684 80.8453L3.40478 61.2068H48.2461Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default SolarPanel
