const dialogStylesDesktop = {
  borderRadius: 20,
}
const dialogStylesMobile = {
  borderRadius: ' 20px 20px 0px 0px',
  position: 'absolute',
  left: 16,
  right: 16,
  bottom: 0,
  margin: 0,
}

export { dialogStylesDesktop, dialogStylesMobile }
