import SvgIcon from '@mui/material/SvgIcon'

const PerformanceIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 17 16"
    >
      <path
        d="M15.8758 7.99036C15.8758 9.48413 15.4182 10.9241 14.5434 12.1623C14.3416 12.4449 14.1262 12.7141 13.8572 12.9832C13.6149 13.2389 13.3188 13.4947 13.0632 13.6965C12.794 13.8984 12.4576 14.1137 12.148 14.2887C11.8116 14.4771 11.5154 14.6117 11.1925 14.7328C9.739 15.2576 8.19133 15.3249 6.72441 14.9077C5.32479 14.504 4.08665 13.6965 3.1446 12.5527C2.22945 11.4625 1.65076 10.0091 1.54311 8.5825C1.42199 7.06176 1.77189 5.58137 2.55245 4.28941C3.30609 3.03782 4.42311 2.0554 5.76891 1.43633C7.10124 0.830717 8.68928 0.655757 10.1293 0.951837C11.5828 1.24792 12.8748 1.96118 13.8976 3.02437C14.9069 4.08754 15.5797 5.41989 15.8085 6.90026C15.8489 7.2098 15.8758 7.62698 15.8758 7.99036C15.8758 8.51521 16.6833 8.51521 16.6833 7.99036C16.6833 6.34848 16.172 4.72006 15.2164 3.37428C14.2744 2.0554 12.9554 1.04605 11.4348 0.480811C9.87359 -0.0978828 8.11059 -0.15171 6.50909 0.305864C4.97488 0.736517 3.57525 1.65165 2.55245 2.87633C1.48927 4.15484 0.856747 5.72941 0.722174 7.38474C0.587587 9.02661 0.99132 10.7223 1.85264 12.1355C2.67357 13.4947 3.91171 14.6117 5.36516 15.2711C6.91283 15.9709 8.63545 16.1728 10.3042 15.8364C11.8788 15.5133 13.3592 14.6924 14.4762 13.5351C15.5932 12.3776 16.3469 10.8704 16.5892 9.28232C16.6564 8.86513 16.6968 8.43446 16.6968 8.01726C16.6833 7.47896 15.8758 7.47894 15.8758 7.99036Z"
        fill={props.color}
      />
      <path
        d="M10.9365 4.53134C11.9324 5.17733 12.6323 6.26741 12.7803 7.45172C12.8611 8.05733 12.8072 8.62256 12.6323 9.1878C12.4843 9.68573 12.2151 10.1972 11.8921 10.5875C11.5287 11.0316 11.1519 11.3411 10.654 11.6103C10.1964 11.866 9.64459 12.0409 9.14664 12.0947C8.55449 12.162 8.02963 12.1081 7.4644 11.9332C6.97991 11.7852 6.46851 11.516 6.09168 11.2065C5.10925 10.3991 4.57093 9.24162 4.55748 7.99004C4.55748 7.46517 3.75 7.46517 3.75 7.99004C3.76345 9.30892 4.28832 10.5875 5.23037 11.5025C6.15897 12.4177 7.43748 12.9291 8.72944 12.9156C10.0349 12.9021 11.3268 12.3504 12.2285 11.4084C13.1301 10.4663 13.6416 9.1878 13.6147 7.88237C13.5743 6.2405 12.7131 4.71976 11.3403 3.83153C11.1519 3.71041 10.8961 3.79116 10.7885 3.97956C10.6808 4.18144 10.7481 4.41022 10.9365 4.53134Z"
        fill={props.color}
      />
      <path
        d="M6.59014 6.49638C7.18229 7.1289 7.7879 7.76142 8.38005 8.39395C8.6223 8.64964 9.06641 8.44778 9.06641 8.11134C9.06641 6.76554 9.06641 5.41974 9.06641 4.07394C9.06641 3.88554 9.06641 3.68367 9.06641 3.49526C9.06641 2.97039 8.25893 2.97039 8.25893 3.49526C8.25893 4.84105 8.25893 6.18685 8.25893 7.53265C8.25893 7.72106 8.25893 7.92292 8.25893 8.11134C8.48772 8.01713 8.7165 7.92293 8.94529 7.82873C8.35314 7.19619 7.74753 6.56367 7.15538 5.93114C6.80548 5.55433 6.22678 6.11955 6.59014 6.49638Z"
        fill={props.color}
      />
      <path
        d="M4.16756 8.55545H3.41392C3.0371 8.55545 2.82178 8.13825 3.02364 7.82872L3.36008 7.33077L3.77728 6.69825C3.9657 6.41563 4.36944 6.41563 4.55784 6.69825L4.97504 7.33077L5.3115 7.82872C5.51336 8.13825 5.29804 8.55545 4.92122 8.55545H4.16756Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}

export default PerformanceIcon
