import { useState } from 'react'
import classnames from 'classnames'
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from '@mui/material/styles'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';

import EnergyUsageYearChart from '../charts/EnergyUsageChart/EnergyUsageYearChart.jsx'
import EnergyOverviewChart from '../charts/EnergyOverviewChart/EnergyOverviewChart.jsx'

import cardStyles from '../../layouts/Card/Card.module.scss'
import commonStyles from '../../shared/scss/common.module.scss'
import styles from './EnergyUsage.module.scss'
import { useGroup } from '../../context/Group.jsx'
import { loginRequest } from '../../utils/authConfig'
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  '& .MuiToggleButtonGroup-grouped': {
    marginRight: 8,
    padding: '11px 16px',
    color: '#001B40',
    font: '600 14px/20px "Inter"',
    letterSpacing: '-0.02em',
    border: 0,
    transition: '.3s ease',
    textTransform: 'capitalize',
    '&:last-child': {
      marginRight: 0,
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&.Mui-selected, &:hover, &.Mui-selected:hover': {
      background: '#E4EAF0',
    },
    '&:not(:first-of-type)': {
      borderRadius: 20,
    },
    '&:first-of-type': {
      borderRadius: 20,
    },
  },
}))

const EnergyUsage = ({ isLandscapeChart, changeChartView }) => {
  const { groupData, groupActions } = useGroup()
  const { activeGroup, isDownloading } = groupData
  const isAuth = useIsAuthenticated()
  const { instance, accounts } = useMsal(useMsalAuthentication())
  const [period, setPeriod] = useState('day')
  const handlePeriod = (event, newPeriod) => {
    if (newPeriod !== null) {
      setPeriod(newPeriod)
    }
  }

  const renderChart = () => {
    switch (period) {
      case 'day':
      case 'week':
        return <EnergyOverviewChart period={period} />
      case 'year':
        return <EnergyUsageYearChart />
    }
  }

const handleClickExport = async () => {
  if (isAuth) {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
    const { accessToken, idToken } = response
    groupActions.exportCSV(activeGroup.project_info_id, accessToken, idToken)
  }
};

  return (
    <Box
      className={classnames(
        cardStyles.card,
        commonStyles.pb0,
        cardStyles.cardOperations,
        cardStyles.solar,
        { [styles.fixed]: isLandscapeChart }
      )}
    >
      <div className={styles.wrapper}>
        <button
          className={classnames(styles.back, {
            [styles.visible]: isLandscapeChart,
          })}
          onClick={changeChartView}
        >
          <ChevronLeftIcon className={styles.backIcon} /> Back
        </button>
        <div
          className={classnames(styles.header, {
            [styles.landscape]: isLandscapeChart,
          })}
        >
          <h3 className={cardStyles.innerTitle}>Energy Usage</h3>

          <StyledToggleButtonGroup
            size="small"
            value={period}
            exclusive
            onChange={handlePeriod}
            aria-label="chart period"
            className={styles.tabs}
          >
            <ToggleButton
              value="day"
              aria-label="day"
            >
              Day
            </ToggleButton>
            <ToggleButton
              value="week"
              aria-label="week"
            >
              Week
            </ToggleButton>
            <ToggleButton
              value="year"
              aria-label="year"
            >
              Year
            </ToggleButton>
            <button 
              className={styles.exportButton}
              onClick={() => handleClickExport()}
            >
              { !isDownloading ? <DownloadIcon /> : <CircularProgress size='20px'/> }
               <span style={{paddingLeft: 5}}>Download</span>
            </button>
          </StyledToggleButtonGroup>
          
        </div>  

        {renderChart()}
      </div>
    </Box>
  )
}

export default EnergyUsage
