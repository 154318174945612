import AmountWithLabel from '../AmountWithLabel/AmountWithLabel.jsx'

import { useGroup } from '../../context/Group.jsx'
import { numberFormatting } from '../../helpers/numberFormatting'

import styles from './TotalSavings.module.scss'

const defaultData = {
  total: 0,
  fcas_credits: 0,
  reduced_cost: 0,
}

const TotalSavings = () => {
  const { groupHelpers } = useGroup()
  const { total, fcas_credits, reduced_cost } = groupHelpers.getActiveGroupData(
    'total_savings',
    defaultData
  )

  return (
    <div className={styles.mainWrapper}>
      <AmountWithLabel value={total} />
      <div className={styles.wrapper}>
        <div className={styles.column}>
          <div className={styles.header}>${numberFormatting(fcas_credits)}</div>
          FCAS credits
        </div>
        <div className={styles.column}>
          <div className={styles.header}>${numberFormatting(reduced_cost)}</div>
          Reduced cost
        </div>
      </div>
    </div>
  )
}

export default TotalSavings
