import classNames from 'classnames'

import AmountWithLabel from '../AmountWithLabel/AmountWithLabel.jsx'

import { useGroup } from '../../context/Group.jsx'

import batteryIcon from '../../assets/img/battery-icon.svg'
import solarIcon from '../../assets/img/solar-icon.svg'
import flexIcon from '../../assets/img/flex-icon.svg'

import savingStyles from './PotentialSavings.module.scss'

const defaultData = {
  annual_savings: 0,
  battery: 0,
  solar: 0,
  flex: 0,
}

const PotentialSavings = () => {
  const { groupHelpers } = useGroup()
  const { annual_savings, battery, solar, flex } =
    groupHelpers.getActiveGroupData('potential_savings', defaultData)

  return (
    <>
      <div className={savingStyles.wrapper}>
        {battery === 0 && solar === 0 && flex === 0 ? (
          <div
            className={classNames(
              savingStyles.column,
              savingStyles.firstColumn
            )}
          >
            <p>Contact Birdwood Energy to know more about the next phase .</p>
          </div>
        ) : (
          <>
            <div
              className={classNames(
                savingStyles.column,
                savingStyles.firstColumn
              )}
            >
              {
                annual_savings === 0 ? (
                  <div>
                    <p>Contact Birdwood Energy to know more about the next phase .</p>
                  </div>
                ) : (
                  <AmountWithLabel
                    header="Additional Savings"
                    value={annual_savings}
                  />
                )
              }
            </div>
            <div>
            {solar > 0 ? (
              <div className={savingStyles.row}>
                <img
                  src={solarIcon}
                  className={savingStyles.img}
                  alt="solar"
                />
                Expand PV up to {solar}kW
              </div>
            ) : (
              annual_savings !== 0 ? (
                <div className={savingStyles.row}>
                  <img
                    src={solarIcon}
                    className={savingStyles.img}
                    alt="solar"
                  />
                  Additional PV not required
                </div>
              ) : (<span/>)
            )}
              {battery > 0 && (
                <div className={savingStyles.row}>
                  <img
                    src={batteryIcon}
                    className={savingStyles.img}
                    alt="battery"
                  />
                  Expand battery {battery}kWh
                </div>
              )}
              {flex > 0 && (
                <div className={savingStyles.row}>
                  <img
                    src={flexIcon}
                    className={savingStyles.img}
                    alt="battery"
                  />
                  Expand flex {flex}kW
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default PotentialSavings
