import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import { useEffect } from 'react'

import { useGroup } from '../../../context/Group.jsx'

import './ProgressToNetChart.scss'

const defaultChartData = {
  self_reliance: 0,
  green_energy: 0,
  net_0_actions: 0,
}

function ProgressToNetChart() {
  const { groupHelpers } = useGroup()
  const chartData = groupHelpers.getActiveGroupData(
    'progress_to_net_0',
    defaultChartData
  )
  useEffect(() => {
    renderChart()
  })

  function renderChart() {
    am4core.useTheme(am4themes_animated)
    am4core.addLicense('ch-custom-attribution')

    const chart = am4core.create('progressToNetChart', am4charts.XYChart)

    chart.data = [
      {
        category: '',
        //the value must be specified as a string and not a number to be able to display them in the tooltip
        SelfReliance: chartData?.self_reliance?.toFixed(2).toString(),
        GreenEnergy: chartData?.green_energy?.toFixed(2).toString(),
        NetActions: chartData?.net_0_actions?.toFixed(2).toString(),
        //maximum value considering offset of two blocks
        max2: 98,
      },
    ]

    chart.colors.list = [
      am4core.color('#427169'),
      am4core.color('#D6E3E1'),
      am4core.color('#57B0F4'),
      am4core.color('#E4EAF0'),
    ]

    //hide bottom line numbers
    chart.numberFormatter.numberFormat = ''
    chart.dy = 20
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'category'
    //hide all grid lines
    categoryAxis.renderer.grid.template.strokeOpacity = 0

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
    valueAxis.min = 0
    valueAxis.max = 100
    //hide all grid lines
    valueAxis.renderer.grid.template.strokeOpacity = 0
    valueAxis.renderer.baseGrid.disabled = true

    const series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.categoryY = 'category'
    series.dataFields.valueX = 'SelfReliance'
    series.name = 'Self Reliance'
    series.stacked = true
    series.zIndex = 3
    const columnTemplate = series.columns.template
    columnTemplate.height = 14
    columnTemplate.column.cornerRadius(100, 100, 100, 100)
    //custom tooltip
    const tooltipHTML =
      '<p style="font-family: Inter; font-weight: 400; font-size: 12px; color: #001B40; padding: 4px 8px; margin: 0;">{SelfReliance}%</p>'
    series.columns.template.tooltipHTML = tooltipHTML
    series.tooltip.getFillFromObject = false
    series.tooltip.background.fill = am4core.color('#FFFFFF')
    series.columns.template.tooltipY = am4core.percent(0)
    series.tooltip.background.cornerRadius = 10
    series.tooltip.background.strokeWidth = 0
    series.tooltip.pointerOrientation = 'down'
    const shadow = series.tooltip.background.filters.getIndex(0)
    shadow.dx = 4
    shadow.dy = 4
    shadow.blur = 10
    shadow.color = am4core.color('#DBE2E9')

    const series2 = chart.series.push(new am4charts.ColumnSeries())
    series2.dataFields.categoryY = 'category'
    series2.dataFields.valueX = 'GreenEnergy'
    series2.name = 'Green Energy/LGCs'
    series2.stacked = true
    series2.dx = -10
    series2.zIndex = 2
    const columnTemplate2 = series2.columns.template
    columnTemplate2.height = 14
    columnTemplate2.column.cornerRadius(100, 100, 100, 100)
    //custom tooltip
    const tooltipHTML2 =
      '<p style="font-family: Inter; font-weight: 400; font-size: 12px; color: #001B40; padding: 4px 8px; margin: 0;">{GreenEnergy}%</p>'
    series2.columns.template.tooltipHTML = tooltipHTML2
    series2.tooltip.getFillFromObject = false
    series2.tooltip.background.fill = am4core.color('#FFFFFF')
    series2.columns.template.tooltipY = am4core.percent(0)
    series2.tooltip.background.cornerRadius = 10
    series2.tooltip.background.strokeWidth = 0
    series2.tooltip.pointerOrientation = 'down'
    const shadow2 = series2.tooltip.background.filters.getIndex(0)
    shadow2.dx = 4
    shadow2.dy = 4
    shadow2.blur = 10
    shadow2.color = am4core.color('#DBE2E9')

    const series3 = chart.series.push(new am4charts.ColumnSeries())
    series3.dataFields.categoryY = 'category'
    series3.dataFields.valueX = 'NetActions'
    series3.name = 'Net 0 actions'
    series3.stacked = true
    series3.dx = -20
    series2.zIndex = 1
    const columnTemplate3 = series3.columns.template
    columnTemplate3.height = 14
    columnTemplate3.column.cornerRadius(100, 100, 100, 100)
    //custom tooltip
    const tooltipHTML3 =
      '<p style="font-family: Inter; font-weight: 400; font-size: 12px; color: #001B40; padding: 4px 8px; margin: 0;">{NetActions}%</p>'
    series3.columns.template.tooltipHTML = tooltipHTML3
    series3.tooltip.getFillFromObject = false
    series3.tooltip.background.fill = am4core.color('#FFFFFF')
    series3.columns.template.tooltipY = am4core.percent(0)
    series3.tooltip.background.cornerRadius = 10
    series3.tooltip.background.strokeWidth = 0
    series3.tooltip.pointerOrientation = 'down'
    const shadow3 = series3.tooltip.background.filters.getIndex(0)
    shadow3.dx = 4
    shadow3.dy = 4
    shadow3.blur = 10
    shadow3.color = am4core.color('#DBE2E9')

    const seriesBg = chart.series.push(new am4charts.ColumnSeries())
    seriesBg.dataFields.categoryY = 'category'
    seriesBg.dataFields.valueX = 'max2'
    seriesBg.stacked = false
    seriesBg.dy = 16
    seriesBg.zIndex = -1
    seriesBg.hiddenInLegend = true
    const columnTemplateBg = seriesBg.columns.template
    columnTemplateBg.height = 14
    columnTemplateBg.column.cornerRadius(100, 100, 100, 100)

    chart.legend = new am4charts.Legend()
    chart.legend.fontSize = 14
    chart.legend.fontFamily = 'Inter'
    chart.legend.fill = '#001B40'
    chart.legend.useDefaultMarker = true
    chart.legend.dy = -20
    chart.legend.itemContainers.template.padding(0, 0, 0, 0)
    const marker = chart.legend.markers.template.children.getIndex(0)
    marker.cornerRadius(50, 50, 50, 50)
    marker.width = 8
    marker.height = 8
    marker.dy = 6
    marker.dx = 12
    //customize disabled lagend and marker
    const disabledLagendLabel =
      chart.legend.labels.template.states.getKey('active')
    disabledLagendLabel.properties.fill = am4core.color('#E4EAF0')
    const disabledMarker = marker.states.create('active')
    disabledMarker.properties.fillOpacity = 0.07
    //Since I'm shifting the second and third series to the left, I need to remove the offset if the user hides the first or second series
    chart.legend.itemContainers.template.events.on('hit', () => {
      setTimeout(() => {
        const isSeriesHidden = series.isHidden || series.isHiding
        const isSeries2Hidden = series2.isHidden || series2.isHiding
        if (
          (isSeriesHidden && !isSeries2Hidden) ||
          (!isSeriesHidden && isSeries2Hidden)
        ) {
          series2.dx = 0
          series3.dx = -10
        }
        if (isSeriesHidden && isSeries2Hidden) {
          series2.dx = 0
          series3.dx = 0
        }
        if (!isSeriesHidden && !isSeries2Hidden) {
          series2.dx = -10
          series3.dx = -20
        }
      }, 0)
    })
    /**
     * ========================================================
     * Enabling responsive features
     * ========================================================
     */
    chart.responsive.useDefault = false
    chart.responsive.enabled = true

    chart.responsive.rules.push({
      relevant: function (target) {
        if (target.pixelWidth <= 482) {
          return true
        }

        return false
      },
      state: function (target, stateId) {
        if (target instanceof am4charts.Chart) {
          const stateChart = target.states.create(stateId)
          stateChart.properties.paddingTop = 0
          stateChart.properties.paddingRight = 0
          stateChart.properties.paddingBottom = 0
          stateChart.properties.paddingLeft = 0
          return stateChart
        }

        if (target instanceof am4charts.Legend) {
          const stateLegend = target.states.create(stateId)
          stateLegend.properties.paddingTop = 0
          stateLegend.properties.paddingRight = 0
          stateLegend.properties.paddingBottom = 0
          stateLegend.properties.paddingLeft = 0
          stateLegend.properties.marginLeft = 0
          stateLegend.properties.fontSize = 10
          target.setStateOnChildren = true
          const stateLegendContainer =
            target.itemContainers.template.states.create(stateId)
          stateLegendContainer.properties.paddingTop = 0
          stateLegendContainer.properties.paddingRight = 0
          stateLegendContainer.properties.paddingBottom = 0
          stateLegendContainer.properties.paddingLeft = 0
          stateLegendContainer.properties.marginLeft = 0
          return stateLegend
        }

        if (target instanceof am4charts.AxisRendererY) {
          const stateAxisRendererY = target.states.create(stateId)
          stateAxisRendererY.properties.inside = true
          stateAxisRendererY.properties.maxLabelPosition = 0.99
          return stateAxisRendererY
        }

        if (
          target instanceof am4charts.AxisLabel &&
          target.parent instanceof am4charts.AxisRendererY
        ) {
          const stateAxisLabelY = target.states.create(stateId)
          stateAxisLabelY.properties.dy = -15
          stateAxisLabelY.properties.paddingTop = 0
          stateAxisLabelY.properties.paddingRight = 0
          stateAxisLabelY.properties.paddingBottom = 0
          stateAxisLabelY.properties.paddingLeft = 0
          return stateAxisLabelY
        }
        return null
      },
    })
  }

  return <div id="progressToNetChart"></div>
}

export default ProgressToNetChart
