import pdfIcon from '../../assets/img/pdf.svg'
import downloadIcon from '../../assets/img/download.svg'

import styles from './DownloadPDF.module.scss'

const DownloadPDF = ({ title, filename }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperHeader}>
        <img
          src={pdfIcon}
          className={styles.pdfIcon}
          alt={`download pdf: ${title}`}
        />
        {title}
      </div>

      <a
        href={filename}
        download
      >
        <img
          src={downloadIcon}
          alt="download icon"
        />
      </a>
    </div>
  )
}

export default DownloadPDF
