import AmountWithLabel from '../AmountWithLabel/AmountWithLabel.jsx'

import { useGroup } from '../../context/Group.jsx'

import styles from './EmissionReduced.module.scss'

const defaultData = {
  carbon_offset_tonnes: 0,
}

const EmissionReduced = () => {
  const { groupHelpers } = useGroup()
  const { carbon_offset_tonnes } = groupHelpers.getActiveGroupData(
    'emissions_reduced',
    defaultData
  )

  return (
    <div className={styles.wrapper}>
      <AmountWithLabel
        header="Carbon Offset"
        value={carbon_offset_tonnes.toFixed(2)}
        type="tonnes"
      />
    </div>
  )
}

export default EmissionReduced
