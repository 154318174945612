import { useGroup } from '../../../context/Group.jsx'
import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js/auto'

const defaultChartData = {
  yearlyData: [],
}

import './EnergyUsageChart.scss'
function EnergyUsageYearChart() {
  const chartRef = useRef(null)
  const { groupHelpers } = useGroup()
  const chartData = groupHelpers.getActiveGroupData(
    'energy_usage',
    defaultChartData
  )
  useEffect(() => {
    let chart
    
    const renderChart = () => {
      const ctx = chartRef.current.getContext('2d');
      const data = chartData?.yearlyData
      chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: data.map(item => new Date(item.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short' })),
          datasets: [
            {
              label: 'Solar',
              data: data.map(item => item.pv_generation_potential_kwh),
              backgroundColor: 'rgba(255, 255, 153, 0.5)',
              borderColor: 'rgb(255, 255, 153)',
              borderWidth: 1,
            },
            {
              label: 'Load',
              data: data.map(item => item.load),
              backgroundColor: 'rgba(173, 216, 230, 0.5)',
              borderColor: 'rgb(173, 216, 230)',
              borderWidth: 1,
            },
            {
              label: 'Grid Import',
              data: data.map(item => item.grid_import || 0),
              backgroundColor: 'rgba(234, 84, 73, 0.3)',
              borderColor: 'rgb(234, 84, 73)',
              borderWidth: 1,
            },
            {
              label: 'Grid Export',
              data: data.map(item => item.grid_export || 0),
              backgroundColor: 'rgba(255, 204, 153, 0.5)',
              borderColor: 'rgb(255, 204, 153)',
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'bottom',
            }
          },  
          scales: {
            y: {
              title: {
                display: true,
                text: 'kWh',
              },
            },
          },  
        }
      });
      };

    renderChart()

    return () => {
      chart.destroy()
    }
  })

  return <canvas ref={chartRef} />
}

export default EnergyUsageYearChart
