import SvgIcon from '@mui/material/SvgIcon'

const RepotsIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 18 21"
    >
      <path
        d="M13.6709 13.9461C13.1465 14.2497 12.6221 14.567 12.0978 14.8706C12.291 14.9258 12.4703 14.9672 12.6635 15.0224C12.1944 14.2359 11.739 13.4493 11.2698 12.6489C11.2146 12.8421 11.1732 13.0215 11.118 13.2147C11.6424 12.9111 12.1668 12.5937 12.6911 12.2901C12.4979 12.2349 12.3186 12.1935 12.1254 12.1383C12.5945 12.9249 13.0499 13.7115 13.5191 14.5118C13.7951 14.9672 14.4989 14.5532 14.2367 14.0979C13.7675 13.3113 13.3121 12.5247 12.8429 11.7243C12.7325 11.5311 12.4703 11.4621 12.2772 11.5725C11.7528 11.8761 11.2284 12.1935 10.704 12.4971C10.5108 12.6075 10.4418 12.8697 10.5522 13.0629C11.0214 13.8495 11.4768 14.636 11.946 15.4364C12.0564 15.6296 12.3186 15.6986 12.5117 15.5882C13.0361 15.2846 13.5605 14.9672 14.0849 14.6636C14.5403 14.3877 14.1263 13.6839 13.6709 13.9461Z"
        fill={props.color}
      />
      <path
        d="M17.3386 18.7727C16.5043 19.2703 15.6847 19.783 14.8504 20.2806C14.7333 20.356 14.6162 20.4163 14.4991 20.4917C14.704 20.552 14.8943 20.5972 15.0992 20.6575C14.2942 19.2552 13.5039 17.868 12.6989 16.4657C12.5232 16.1641 12.3476 15.8475 12.172 15.5459C12.1134 15.757 12.0695 15.953 12.011 16.1641C12.8452 15.6665 13.6649 15.1539 14.4991 14.6563C14.6162 14.5809 14.7333 14.5206 14.8504 14.4452C14.6455 14.3849 14.4552 14.3396 14.2503 14.2793C15.0114 15.5911 15.7579 16.9181 16.519 18.2299C16.7385 18.6219 16.958 18.9989 17.1776 19.3909C17.4703 19.8885 18.2168 19.4362 17.9387 18.9386C17.1337 17.5363 16.3433 16.1491 15.5383 14.7467C15.3627 14.4452 15.187 14.1285 15.0114 13.827C14.8943 13.6159 14.6162 13.5405 14.4113 13.6611C13.577 14.1587 12.7574 14.6713 11.9231 15.1689C11.806 15.2443 11.689 15.3047 11.5719 15.38C11.367 15.5007 11.2938 15.7872 11.4109 15.9983C12.1573 17.3101 12.9184 18.6219 13.6649 19.9338C13.8844 20.3258 14.1186 20.7179 14.3381 21.1099C14.4552 21.321 14.7333 21.3964 14.9382 21.2758C15.7725 20.7782 16.5921 20.2655 17.4264 19.7679C17.5435 19.6925 17.6606 19.6322 17.7777 19.5568C18.2607 19.2703 17.8216 18.4862 17.3386 18.7727Z"
        fill={props.color}
      />
      <path
        d="M13.4915 4.21748C14.0987 5.26625 14.3747 6.42542 14.2643 7.63979C14.1677 8.71616 13.7123 9.87532 13.0085 10.7171C12.2219 11.6693 11.1869 12.3317 10.014 12.649C8.88241 12.9388 7.72324 12.8836 6.60547 12.4835C5.5429 12.0971 4.54932 11.3519 3.90074 10.4135C3.19696 9.40613 2.83817 8.24697 2.85197 7.0602C2.86577 5.91483 3.22456 4.81086 3.88694 3.84489C4.54932 2.87891 5.58429 2.10613 6.70206 1.71974C7.81983 1.33335 8.979 1.30575 10.0968 1.60935C11.2283 1.92674 12.2357 2.57532 12.9947 3.4999C13.1741 3.72069 13.3397 3.96909 13.4915 4.21748C13.7675 4.67287 14.4851 4.25888 14.2091 3.80349C13.5191 2.64432 12.4841 1.70594 11.2559 1.16776C10.0278 0.62957 8.68921 0.46398 7.36445 0.698574C4.70112 1.18156 2.53458 3.4033 2.12059 6.09423C1.9136 7.44659 2.10679 8.79895 2.71397 10.0271C3.30736 11.2277 4.28713 12.2489 5.4601 12.8836C6.60547 13.5046 7.94403 13.7806 9.2412 13.6426C10.6212 13.4908 11.9183 12.925 12.9533 12.0005C14.9542 10.2203 15.658 7.23959 14.6645 4.75566C14.5403 4.42447 14.3885 4.12088 14.2091 3.81729C13.9331 3.3481 13.2293 3.76209 13.4915 4.21748Z"
        fill={props.color}
      />
      <path
        d="M15.0092 9.14392C15.0092 9.14392 15.1058 9.30951 15.161 9.39231C15.7682 10.3997 16.3615 11.4071 16.9687 12.4144C17.2447 12.8698 16.5271 13.2838 16.2511 12.8284C16.1684 12.6904 16.0718 12.5386 15.989 12.4006C15.5474 11.6555 15.092 10.8965 14.6504 10.1513C14.6504 10.1651 14.9126 9.62691 15.0092 9.14392Z"
        fill={props.color}
      />
      <path
        d="M13.6022 9.1303C13.6022 9.1303 12.305 9.88928 11.8082 10.1653C11.615 9.55809 11.408 8.9509 11.2148 8.34372C10.8147 7.11555 10.4145 5.88739 10.0143 4.67302C9.91768 4.39703 9.83488 4.10724 9.73828 3.83124C9.61408 3.43106 9.0759 3.44486 8.9517 3.83124C8.73091 4.46603 8.51011 5.10081 8.27552 5.73559C7.93053 6.71537 7.58554 7.69514 7.24055 8.67491C6.74376 7.92973 6.23318 7.17075 5.73639 6.42557C5.63979 6.28758 5.55699 6.14958 5.4604 6.01158C5.2534 5.69419 4.83941 5.77699 4.71522 6.10818C4.43923 6.82576 4.14943 7.55714 3.87344 8.27472C3.74924 8.60591 3.51465 9.1993 3.51465 9.1993C3.55605 9.48909 4.01144 10.1791 4.01144 10.1791C4.41163 9.1579 4.81182 8.13673 5.21201 7.11555C5.21201 7.10175 5.21201 7.10175 5.22581 7.08795C5.73639 7.84693 6.23318 8.60591 6.74376 9.36489C6.84036 9.50289 6.92316 9.64088 7.01976 9.77888C7.22675 10.0825 7.65454 10.0135 7.76494 9.68228C7.98573 9.0475 8.20652 8.41272 8.44112 7.77794C8.74471 6.92236 9.0483 6.06678 9.35189 5.21121C9.47609 5.5976 9.60028 5.98398 9.72448 6.37037C10.1247 7.59854 10.5249 8.82671 10.925 10.0411C11.0216 10.3171 11.1044 10.6069 11.201 10.8829C11.2838 11.1312 11.5598 11.2692 11.8082 11.1312C12.167 10.9242 12.5258 10.7311 12.8708 10.5241C12.9122 10.4827 13.4366 9.64088 13.6022 9.1303Z"
        fill={props.color}
      />
      <path
        d="M3.41784 11.5172C3.41784 11.5172 3.10045 12.3314 2.93485 12.7454C2.78306 13.1318 2.28627 13.1318 2.13447 12.7454C2.06547 12.5798 2.01028 12.4142 1.94128 12.2486C1.59629 11.3793 1.2651 10.5099 0.920107 9.62671C0.920107 9.62671 0.40952 8.41234 0.837309 8.24674C1.2651 8.08115 1.49969 8.84013 1.55489 9.00572C1.87228 9.8337 2.20347 10.6617 2.52086 11.4897C2.63126 11.1999 2.72786 10.9653 2.83825 10.6755C2.86585 10.6893 3.05905 11.1171 3.41784 11.5172Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}

export default RepotsIcon
