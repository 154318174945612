import Box from '@mui/material/Box'
import classnames from 'classnames'

import StatusData from '../StatusData/StatusData.jsx'
import FlexPanel from './FlexPanel.jsx'

import { useGroup } from '../../context/Group.jsx'

import cardStyles from '../../layouts/Card/Card.module.scss'

const defaultFlexData = {
  size: '0 kW',
  status: 'offline',
}

const FlexStatus = () => {
  const { groupHelpers } = useGroup()
  const flexData = groupHelpers.getActiveGroupData('flex', defaultFlexData)

  return (
    <Box
      className={classnames(
        cardStyles.card,
        cardStyles.cardOperations,
        cardStyles.flex
      )}
    >
      <div className={cardStyles.innerTitle}>Flex</div>

      <div className={cardStyles.imgWrapper}>
        <FlexPanel status={flexData.status} />
      </div>

      <StatusData
        status={flexData.status}
        list={{ size: `${flexData.size} kW` }}
      />
    </Box>
  )
}

export default FlexStatus
