import Box from '@mui/material/Box'
import classnames from 'classnames'

import SolarPanel from './SolarPanel.jsx'
import StatusData from '../StatusData/StatusData.jsx'

import { useGroup } from '../../context/Group.jsx'

import cardStyles from '../../layouts/Card/Card.module.scss'

const defaultSolarData = {
  size: 0,
  output: 0,
  status: 'offline',
}

const SolarPanelStatus = () => {
  const { groupHelpers } = useGroup()
  const solarData = groupHelpers.getActiveGroupData('solar', defaultSolarData)
  const normSolarData = {
    status: solarData.status,
    other: {
      size: solarData.size + ' kW',
      output: solarData.output + ' kW',
    },
  }
  return (
    <Box
      className={classnames(
        cardStyles.card,
        cardStyles.cardOperations,
        cardStyles.solar
      )}
    >
      <div className={cardStyles.innerTitle}>Solar</div>

      <div className={cardStyles.imgWrapper}>
        <SolarPanel status={normSolarData.status} />
      </div>

      <StatusData
        status={normSolarData.status}
        list={normSolarData.other}
      />
    </Box>
  )
}

export default SolarPanelStatus
