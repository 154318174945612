import { NavLink, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import logo from '../../assets/img/logo.svg'
import { Drawer } from '../Drawer/Drawer.jsx'
import { sidebarItems, icons } from '../../utils/constants/sidebarItems.js'
import { drawer, sidebarIconButton } from '../../shared/muiStyles/sidebar'

import sidebarStyles from './Sidebar.module.scss'
import { useGroup } from '../../context/Group.jsx'
import { isJuniorOrSeniorCompany } from '../../utils/common.js'

const Sidebar = ({ open, toggleSidebar }) => {
  const { pathname } = useLocation()
  const { groupData } = useGroup()
  const companyName = groupData.activeGroup ? groupData.activeGroup.company_name : ''

  return (
    <aside
      className={classnames(sidebarStyles.sidebar, {
        [sidebarStyles.sidebarClosed]: !open,
      })}
    >
      <Drawer
        variant="permanent"
        open={open}
        sx={drawer}
        className={sidebarStyles.drawer}
      >
        <div className={sidebarStyles.logo}>
          <img
            src={logo}
            alt="birdwood"
          />
          {open && <div className={sidebarStyles.logoName}>Smarthub</div>}
        </div>
        <List
          sx={{ zIndex: 3 }}
          className={sidebarStyles.list}
        >
          {sidebarItems.map(({ label, path, icon, width, height }) => {
            // Check if the company is in juniorSeniorCompanies
            const isJuniorOrSenior = isJuniorOrSeniorCompany(companyName);
            
            if (isJuniorOrSenior && label === 'Reports') {
              return null;
            }
            const Icon = icons[icon]
            const button = (
              <ListItemButton
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  px: 0,
                  py: 0,
                  minHeight: 'auto',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                  cursor: 'pointer',
                }}
                className={sidebarStyles.navBtn}
                disableRipple
              >
                <div className={sidebarStyles.navIcon}>
                  {icon && (
                    <Icon
                      width={width}
                      height={height}
                      color="#FFFFFF"
                      sx={{ height: 'auto', width: 'auto' }}
                    />
                  )}
                </div>

                <ListItemText
                  primary={label}
                  sx={{
                    opacity: open ? 1 : 0,
                    display: open ? 'block' : 'none',
                    my: 0,
                  }}
                  className={sidebarStyles.navText}
                />
              </ListItemButton>
            )

            return (
              <ListItem
                className={sidebarStyles.navItem}
                key={label}
                disablePadding
              >
                <NavLink
                  to={path}
                  className={classnames(sidebarStyles.navLink, {
                    [sidebarStyles.navLinkActive]: pathname === path,
                  })}
                >
                  {button}
                </NavLink>
              </ListItem>
            )
          })}
        </List>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleSidebar}
          edge="start"
          sx={sidebarIconButton}
          className={sidebarStyles.toggle}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Drawer>
    </aside>
  )
}

export default Sidebar
