import Grid from '@mui/material/Grid'

import Card from '../../layouts/Card/Card.jsx'
import EnergyFlowsChart from '../../components/charts/EnergyFlows/EnergyFlowsChart.jsx'
import Weather from '../../components/Weather/Weather.jsx'
import Alarms from '../../components/Alarms/Alarms.jsx'
import DownloadPDF from '../../components/DownloadPDF/DownloadPDF.jsx'
import { useGroup } from '../../context/Group.jsx'

const Home = () => {
  const { groupData } = useGroup()
  const battery_spec_filename = groupData?.activeGroup?.battery_spec_filename
  const solar_spec_filename = groupData?.activeGroup?.solar_spec_filename

  let batteryUrl = ''
  if (battery_spec_filename && battery_spec_filename !== '') {
    batteryUrl = require(`../../assets/${battery_spec_filename}`)
  }

  return (
    <Grid
      container
      direction="row"
      spacing={2}
    >
      <Grid
        item
        xs={12}
        lg={8}
      >
        <Card
          singleCard
          title="Operations"
          innerTitle="Energy Flows & System"
          cardDisplay="block"
          contentOrientation="center"
        >
          <EnergyFlowsChart />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        lg={4}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={12}
          >
            <Card
              singleCard
              innerTitle="Weather"
              cardDisplay="block"
              paddingInsteadTitle="true"
            >
              <Weather />
            </Card>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={12}
          >
            <Card
              singleCard
              innerTitle="Alarms"
              orientation="start-column"
            >
              <Alarms />
            </Card>
          </Grid>

          {battery_spec_filename && battery_spec_filename !== '' && (
            <Grid
              item
              xs={12}
              md={6}
              lg={12}
            >
              <Card
                singleCard
                innerTitle="Detailed Battery Specification"
                orientation="start-column"
              >
                <DownloadPDF
                  title="Detailed battery specification.pdf"
                  filename={batteryUrl}
                />
              </Card>
            </Grid>
          )}
          {solar_spec_filename && solar_spec_filename !== '' && (
            <Grid
              item
              xs={12}
              md={6}
              lg={12}
            >
              <Card
                singleCard
                innerTitle="Detailed Solar Specification"
                orientation="start-column"
              >
                <DownloadPDF title="Detailed solar specification.pdf" />
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Home
