import { Image, Text } from 'react-konva'
import useImage from 'use-image'

import charging25 from '../../../assets/img/batteries/charging/25.svg'
import charging50 from '../../../assets/img/batteries/charging/50.svg'
import charging75 from '../../../assets/img/batteries/charging/75.svg'
import charging100 from '../../../assets/img/batteries/charging/100.svg'
import discharging25 from '../../../assets/img/batteries/discharging/25.svg'
import discharging50 from '../../../assets/img/batteries/discharging/50.svg'
import discharging75 from '../../../assets/img/batteries/discharging/75.svg'
import discharging100 from '../../../assets/img/batteries/discharging/100.svg'
import error25 from '../../../assets/img/batteries/error/25.svg'
import error50 from '../../../assets/img/batteries/error/50.svg'
import error75 from '../../../assets/img/batteries/error/75.svg'
import error100 from '../../../assets/img/batteries/error/100.svg'
import idle25 from '../../../assets/img/batteries/idle/25.svg'
import idle50 from '../../../assets/img/batteries/idle/50.svg'
import idle75 from '../../../assets/img/batteries/idle/75.svg'
import idle100 from '../../../assets/img/batteries/idle/100.svg'
import error from '../../../assets/img/batteries/error/25.svg'

import { textSettings, titleSettings } from '../../../constants/energyFlowChart'

const desckDeviation = {
  leftTextX: -60,
  rightTextX: 10,
}
const mobDeviation = {
  leftTextX: -45,
  rightTextX: 2,
}
const images = [
  {
    charging25: charging25,
  },
  {
    charging50: charging50,
  },
  {
    charging75: charging75,
  },
  {
    charging100: charging100,
  },
  {
    discharging25: discharging25,
  },
  {
    discharging50: discharging50,
  },
  {
    discharging75: discharging75,
  },
  {
    discharging100: discharging100,
  },
  {
    error25: error25,
  },
  {
    error50: error50,
  },
  {
    error75: error75,
  },
  {
    error100: error100,
  },
  {
    idle25: idle25,
  },
  {
    idle50: idle50,
  },
  {
    idle75: idle75,
  },
  {
    idle100: idle100,
  },
]

function Battery({
  x,
  y,
  imgWidth,
  imgHeight,
  canvasWidth,
  fontSize,
  data,
  isSmall,
  isBigContainer,
}) {
  const { duration, soc: value, status, size } = data
  const range = value <= 25 ? 25 : value <= 50 ? 50 : value <= 75 ? 75 : 100
  let imagePrefix = `${status}${range}`

  const deviation = isSmall ? mobDeviation : desckDeviation
  const centerX = canvasWidth / 2
  const time = `${parseFloat((duration || 0).toFixed(1))} hrs`
  const imageObject = images.filter(
    (image) => Object.keys(image)[0] === imagePrefix
  )[0]
  const errorUrl = error
  const imageUrl = imageObject ? Object.values(imageObject)[0] : errorUrl
  const [image] = useImage(imageUrl)
  const imageSpacing = 24
  const rowHeight = 20
  const rowMargin = 8
  const imagePos = y - (rowHeight + rowMargin) * 2 - imageSpacing - imgHeight
  const imgHeaderPosDesktop = imagePos - imageSpacing - rowHeight
  const imgHeaderMobile = imagePos - imageSpacing
  const imgHeaderPos = isBigContainer ? imgHeaderPosDesktop : imgHeaderMobile
  const textWidth = 50

  return (
    <>
      <Text
        y={imgHeaderPos}
        width={canvasWidth}
        align="center"
        text="Battery"
        fontSize={fontSize}
        {...titleSettings}
      />
      <Image
        x={x}
        y={imagePos}
        width={imgWidth}
        height={imgHeight}
        image={image}
      />
      <Text
        x={centerX + deviation.leftTextX}
        y={y - (rowHeight + rowMargin) * 2}
        text="Size:"
        fontSize={fontSize}
        {...textSettings}
      />
      <Text
        x={centerX + deviation.rightTextX}
        y={y - (rowHeight + rowMargin) * 2}
        text={`${Math.round(size * 100) / 100} kW`}
        width={textWidth}
        align="right"
        fontSize={fontSize}
        {...titleSettings}
      />
      <Text
        x={centerX + deviation.leftTextX}
        y={y - rowHeight - rowMargin}
        text="Duration:"
        fontSize={fontSize}
        {...textSettings}
      />
      <Text
        x={centerX + deviation.rightTextX}
        y={y - rowHeight - rowMargin}
        text={time}
        width={textWidth}
        align="right"
        fontSize={fontSize}
        {...titleSettings}
      />
      <Text
        x={centerX + deviation.leftTextX}
        y={y}
        text="Charge:"
        fontSize={fontSize}
        {...textSettings}
      />
      <Text
        x={centerX + deviation.rightTextX}
        y={y}
        text={`${Math.round(value * 100) / 100}%`}
        align="right"
        width={textWidth}
        fontSize={fontSize}
        {...titleSettings}
      />
    </>
  )
}

export default Battery
