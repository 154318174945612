import { Image, Text } from 'react-konva'
import useImage from 'use-image'

import dermandImg from '../../../assets/img/dermand.svg'
import { titleSettings, textSettings } from '../../../constants/energyFlowChart'

const desckDeviation = {
  headerX: -68,
  imgX: 0,
  textX: -63,
  additionalFromImgY: 0,
}

const mobDeviation = {
  headerX: -48,
  imgX: -2,
  textX: -44,
  additionalFromImgY: -5,
}

function Demand({ x, y, imgWidth, imgHeight, canvasHeight, fontSize, value }) {
  const [image] = useImage(dermandImg)
  const deviation = imgWidth == 42 ? mobDeviation : desckDeviation
  const centerY = canvasHeight / 2
  return (
    <>
      <Text
        x={x + deviation.headerX}
        y={centerY - imgHeight / 2 - 32 - deviation.additionalFromImgY}
        text="Demand"
        fontSize={fontSize}
        {...titleSettings}
      />
      <Image
        x={x - imgWidth + deviation.imgX}
        y={y}
        width={imgWidth}
        height={imgHeight}
        image={image}
      />
      <Text
        x={x + deviation.textX}
        y={centerY + imgHeight / 2 + 20 + deviation.additionalFromImgY}
        text={`${Math.round(value * 100) / 100} kW`}
        fontSize={fontSize}
        {...textSettings}
      />
    </>
  )
}

export default Demand
