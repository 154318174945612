import { useEffect, useState } from 'react'
import { Line } from 'react-konva'

function DashLine({ x, y, points }) {
  const [zIndex, setZindex] = useState(0)
  useEffect(() => {
    //hack zIndex bug in react konva
    setZindex(1)
  }, [])
  return (
    <Line
      x={x}
      y={y}
      points={points}
      strokeWidth={2}
      dash={[10, 5]}
      zIndex={zIndex}
      stroke="#E4EAF0"
    />
  )
}

export default DashLine
