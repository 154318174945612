import { useState } from 'react'

import logo from '../../assets/img/logo.svg'
import microsoftLogo from '../../assets/img/logo_microsoft.svg'

import Spinner from '../../components/Spinner/Spinner.jsx'

import styles from './Login.module.scss'

const Login = ({ authUser, inProgress }) => {
  const [isSubmitting, setSubmitting] = useState(inProgress === 'none')

  const handleSubmit = () => {
    setSubmitting(true)

    setTimeout(() => {
      setSubmitting(false)
      authUser()
    }, 2000)
  }

  return (
    <main className={styles.wrapper}>
      <img
        src={logo}
        width={186}
        height={116}
        className={styles.logo}
        alt="birdwood logo"
      />

      <h1 className={styles.header}>Welcome to Birdwood Energy!</h1>
      <button
        className={styles.button}
        onClick={handleSubmit}
      >
        <img
          src={microsoftLogo}
          className={styles.icon}
          alt="microsoft logo"
        />
        Sign in with Microsoft
      </button>
      {isSubmitting && <Spinner color="#ea5449" />}
    </main>
  )
}

export default Login
