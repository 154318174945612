import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react'

import { dropdown, dropdownItem } from '../../shared/muiStyles/dropdown'
import logo from '../../assets/img/logo.svg'

import { useGroup } from '../../context/Group.jsx'
import groupStyles from './Group.module.scss'
import { loginRequest } from '../../utils/authConfig'

const GroupList = () => {
  const { instance, accounts } = useMsal(useMsalAuthentication())
  const isAuth = useIsAuthenticated()
  const { groupData, groupActions } = useGroup()
  const { groupsList, activeGroup } = groupData
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const isNotSingleGroup = groupsList.length > 1

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleMenuItemClick = async (group) => {
    if (isAuth) {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      const { accessToken, idToken } = response
      groupActions.changeActiveGroup(group, accessToken, idToken)
    }
    setAnchorEl(null)
  }

  useEffect(() => {
    const fetchData = async () => {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      const { accessToken, idToken } = response
      groupActions.refreshActiveGroup(
        activeGroup.project_info_id,
        accessToken,
        idToken
      )
    }
    const refreshInterval = setInterval(() => {
      if (activeGroup?.project_info_id && isAuth) {
        fetchData()
      }
    }, 1000 * 60 * 1000)
    return () => clearInterval(refreshInterval)
  }, [activeGroup, groupActions, accounts, instance, isAuth])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <img
            src={logo}
            alt="birdwood"
            className={groupStyles.logo}
          />
        </Box>
        <div className={groupStyles.groupName}>
          {activeGroup && activeGroup.company_name}
        </div>
        {isNotSingleGroup && (
          <KeyboardArrowDownIcon
            sx={{ width: 24, height: 24, color: '#dropdown' }}
          />
        )}
      </Box>
      {isNotSingleGroup && (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClick={handleClose}
          PaperProps={{
            sx: dropdown,
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          {groupsList.map(
            (group) =>
              group.id !== activeGroup.project_info_id && (
                <MenuItem
                  sx={dropdownItem}
                  key={group.id}
                  onClick={() => handleMenuItemClick(group)}
                >
                  {group.name}
                </MenuItem>
              )
          )}
        </Menu>
      )}
    </>
  )
}

export default GroupList
