import SvgIcon from '@mui/material/SvgIcon'

const PinIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 13 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.460938 5.99141C0.460938 2.70305 3.11241 0.0333862 6.38872 0.0333862C9.66502 0.0333862 12.3165 2.70305 12.3165 5.99141C12.3165 7.57236 11.3314 9.60668 9.51614 12.0525L9.51356 12.056L9.0773 12.6315C8.54602 13.3176 7.95017 14.0373 7.28936 14.7941L7.28709 14.7967L6.72337 15.4319C6.67696 15.4902 6.61517 15.5366 6.54249 15.5646C6.37739 15.6282 6.19018 15.5836 6.07149 15.4524C5.24748 14.5415 4.45685 13.6011 3.70115 12.6328L3.69744 12.628L3.26128 12.0525C1.44596 9.60669 0.460938 7.57236 0.460938 5.99141ZM6.38679 14.5222L6.64491 14.2314L6.64605 14.2301C7.29688 13.4847 7.8809 12.779 8.39948 12.1094L8.8304 11.5409C10.635 9.10912 11.4609 7.272 11.4609 5.99141C11.4609 3.17111 9.18807 0.888942 6.38872 0.888942C3.58936 0.888942 1.31649 3.17111 1.31649 5.99141C1.31649 7.27199 2.14238 9.10909 3.94699 11.5409L4.37747 12.1088C5.02175 12.9342 5.69185 13.7391 6.38679 14.5222Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.38862 4.09746C5.35922 4.09746 4.52473 4.93195 4.52473 5.96135C4.52473 6.99075 5.35922 7.82524 6.38862 7.82524C7.41802 7.82524 8.25251 6.99075 8.25251 5.96135C8.25251 4.93195 7.41802 4.09746 6.38862 4.09746ZM3.66918 5.96135C3.66918 4.45944 4.88671 3.24191 6.38862 3.24191C7.89053 3.24191 9.10807 4.45944 9.10807 5.96135C9.10807 7.46326 7.89053 8.6808 6.38862 8.6808C4.88671 8.6808 3.66918 7.46326 3.66918 5.96135Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}

export default PinIcon
