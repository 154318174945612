import { forwardRef } from 'react'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import classnames from 'classnames'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
  dialogStylesDesktop,
  dialogStylesMobile,
} from '../../shared/muiStyles/dialog'

import styles from './logoutModal.module.scss'

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  )
})

const LogoutModal = ({ open, onClose, logoutClick }) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const LogoutDialog = styled(Dialog)(() => ({
    '& .MuiDialog-paper': isSmall ? dialogStylesMobile : dialogStylesDesktop,
  }))

  return (
    <LogoutDialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      aria-labelledby="logout-title"
      aria-describedby="logout-description"
    >
      <Box className={styles.logoutWrapper}>
        <CloseIcon
          className={styles.close}
          onClick={onClose}
        />
        <h2
          id="modal-modal-title"
          className={styles.logoutHeader}
        >
          Log Out
        </h2>
        <div
          className={styles.logoutDescr}
          id="modal-modal-description"
        >
          Are you sure, that you want to log out?
        </div>
        <div className={styles.logoutBtns}>
          <button
            className={classnames(styles.logoutBtn, styles.logoutBtnCancel)}
            onClick={onClose}
          >
            No
          </button>
          <button
            className={classnames(styles.logoutBtn, styles.logoutBtnConfirm)}
            onClick={logoutClick}
          >
            Yes
          </button>
        </div>
      </Box>
    </LogoutDialog>
  )
}

export default LogoutModal
