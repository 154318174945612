import { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import QuarterReports from '../../components/QuarterReports/QuarterReports.jsx'
import NetActions from '../../components/NetActions/NetActions.jsx'

import './Reports.scss'
import { useGroup } from '../../context/Group.jsx'
import { isJuniorOrSeniorCompany } from '../../utils/common.js'
import { useNavigate } from 'react-router-dom'

const Reports = () => {
  const theme = useTheme()
  const { groupData } = useGroup()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const companyName = groupData.activeGroup ? groupData.activeGroup.company_name : ''
  const navigate = useNavigate()

  useEffect(() => {
    const isJuniorOrSenior = isJuniorOrSeniorCompany(companyName);

    if (isJuniorOrSenior) {
      navigate('/')
    }
  }, [companyName, navigate])

  return (
    <Grid
      container
      direction="column"
      rowSpacing={isSmall ? 2 : 5}
      columnSpacing={isSmall ? 0 : 4}
    >
      <Grid
        item
        xs={12}
      >
        <QuarterReports />
        <NetActions />
      </Grid>
    </Grid>
  )
}

export default Reports
