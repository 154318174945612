import { Link } from 'react-router-dom'
import classnames from 'classnames'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

import { useGroup } from '../../context/Group.jsx'

import cardStyles from './Card.module.scss'
import commonStyles from '../../shared/scss/common.module.scss'

const Card = ({
  title,
  paddingInsteadTitle,
  subTitle,
  innerTitle,
  icon,
  children,
  background,
  isReport,
  singleCard,
  link,
  orientation,
  wrapperModifier = '',
  cardModifier = '',
  contentOrientation,
  cardDisplay,
}) => {
  const { groupData } = useGroup()
  const iconRenderWithoutLink = (
    <div
      className={classnames(cardStyles.headerIcon, {
        [cardStyles.headerIconReport]: isReport,
      })}
    >
      {icon}
    </div>
  )
  const iconLinkRender = <Link to={link}>{iconRenderWithoutLink}</Link>
  const titleMarkup = (
    <h2 className={cardStyles.header}>
      {title}
      {subTitle && <span className={cardStyles.subHeader}>({subTitle})</span>}
    </h2>
  )
  const iconMarkup = icon && (link ? iconLinkRender : iconRenderWithoutLink)
  const titleRender = (
    <Box className={cardStyles.headerWrapper}>
      {groupData.isLoading ? <Skeleton>{titleMarkup}</Skeleton> : titleMarkup}
      {groupData.isLoading ? (
        <Skeleton variant="circular">{iconMarkup}</Skeleton>
      ) : (
        iconMarkup
      )}
    </Box>
  )
  const cardRender = singleCard ? (
    <Box
      className={classnames(cardStyles.card, {
        [cardStyles.start]: orientation === 'start',
        [cardStyles.startColumn]: orientation === 'start-column',
        [cardStyles.block]: cardDisplay === 'block',
        [commonStyles.pb0]: cardModifier.includes('pb-0'),
      })}
      style={background ? { backgroundImage: `url(${background})` } : {}}
    >
      {innerTitle && (
        <div className={cardStyles.cardHeader}>
          <h3 className={cardStyles.innerTitle}>{innerTitle}</h3>
        </div>
      )}
      <div
        className={classnames(cardStyles.cardsWrapper, {
          [commonStyles.w100]: wrapperModifier.includes('w-100'),
          [cardStyles.center]: contentOrientation === 'center',
        })}
      >
        {children}
      </div>
    </Box>
  ) : (
    <Box>{children}</Box>
  )

  return (
    <Box
      className={classnames(cardStyles.cardWrapper, {
        [cardStyles.paddingInsteadTitle]: paddingInsteadTitle === 'true',
      })}
    >
      {title && titleRender}
      {groupData.isLoading ? (
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            borderRadius: 14,
          }}
        >
          {cardRender}
        </Skeleton>
      ) : (
        cardRender
      )}
    </Box>
  )
}

export default Card
