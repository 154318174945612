import AmountWithLabel from '../AmountWithLabel/AmountWithLabel.jsx'
import PerformanceChart from '../charts/PerformanceChart/PerformanceChart.jsx'

import { useGroup } from '../../context/Group.jsx'

import styles from './Performance.module.scss'
import { isJuniorOrSeniorCompany } from '../../utils/common.js'

const defaultData = {
  ytd_savings: 0,
  ytd_savings_percent: 0,
}
// groupData.activeGroup.performance
const Performance = () => {
  const { groupHelpers, groupData } = useGroup()
  const performanceData = groupHelpers.getActiveGroupData(
    'performance',
    defaultData
  )
  const companyName = groupData.activeGroup ? groupData.activeGroup.company_name : ''

  return (
    <div className={styles.container}>
      <PerformanceChart />
      {
        !isJuniorOrSeniorCompany(companyName) ? <div className={styles.values}>
          <AmountWithLabel
            header="Total Savings"
            value={performanceData.ytd_savings}
          /> </div> : ''
      }
    </div>
  )
}

export default Performance
