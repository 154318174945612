import HomeIcon from '../../assets/icons/Home.jsx'
import PinIcon from '../../assets/icons/Pin.jsx'
import PerformanceIcon from '../../assets/icons/Performance.jsx'
import OperationsIcon from '../../assets/icons/Operations.jsx'
import ReportsIcon from '../../assets/icons/Reports.jsx'

export const icons = {
  HomeIcon,
  PinIcon,
  PerformanceIcon,
  OperationsIcon,
  ReportsIcon,
}

export const sidebarItems = [
  {
    label: 'Home',
    path: '/',
    icon: 'HomeIcon',
    width: 15,
    height: 14,
    color: '#FFFFFF',
  },
  {
    label: 'Sites',
    path: '/sites',
    icon: 'PinIcon',
    width: 13,
    height: 16,
    color: '#FFFFFF',
  },
  {
    label: 'Performance',
    path: '/performance',
    icon: 'PerformanceIcon',
    width: 17,
    height: 16,
    color: '#FFFFFF',
  },
  {
    label: 'Operations',
    path: '/operations',
    icon: 'OperationsIcon',
    width: 16,
    height: 16,
    color: '#FFFFFF',
  },
  {
    label: 'Reports',
    path: '/reports',
    icon: 'ReportsIcon',
    width: 18,
    height: 21,
    color: '#FFFFFF',
  },
]
