import { useEffect, useState } from 'react'
import { Arrow, Text, Line } from 'react-konva'
import { arrowCaptionTextSettings } from '../../../constants/energyFlowChart'

const defaultCaption = {
  text: '',
  x: 'end',
  y: 'top',
}

const desckDeviation = {
  centerCaptionX: 35,
}
const mobDeviation = {
  centerCaptionX: 25,
}

const EnergyFlowArrow = ({
  canvasWidth,
  containerWidth,
  isSmall,
  x,
  y,
  points,
  caption = defaultCaption,
  fontSize,
  name,
  straightLine,
  coef: linePosCoef,
  rotation,
}) => {
  const [captionZindex, setCaptionZindex] = useState(0)
  useEffect(() => {
    //hack zIndex bug in react konva
    setCaptionZindex(29)
  }, [])
  const isBigContainer = containerWidth >= 500
  const fontSizeAdaptive = isBigContainer
    ? fontSize
    : isBigContainer && !isSmall
    ? 12
    : 10
  const arrowPointerSizes = {
    width: isBigContainer ? 10 : 5,
    height: isBigContainer ? 10 : 5,
  }

  const transformPointsToLineCoords = (coords) => {
    const dimensions = []
    coords.map((item, index) => {
      const newIndex = (index, Math.floor(index / 2))

      if (!dimensions[newIndex]) {
        dimensions[(index, Math.floor(index / 2))] = []
      }

      dimensions[(index, Math.floor(index / 2))].push(item)
    })

    return dimensions
  }
  const divideLineToParts = (lineCoords) => {
    const [start, end] = lineCoords
    const [startX, startY] = start
    const [endX, endY] = end
    const partsCoords = []
    const partsToDivide = 2

    for (let part = 0; part < partsToDivide; part++) {
      let coef = part === 0 ? linePosCoef.start : linePosCoef.end
      const coordX = +parseFloat((endX - startX) * coef).toFixed(2)
      const coordY = +parseFloat((endY - startY) * coef).toFixed(2)
      //points for line, if calculated before, get this coords, else get start points
      const previousCoords =
        part == 0 ? points.slice(0, 2) : partsCoords[part - 1].slice(2, 4)

      partsCoords[part] = [...previousCoords, coordX, coordY]
    }

    return partsCoords
  }

  //first line is only two firstArros part
  const firstLine = transformPointsToLineCoords(
    straightLine
      ? [...points.slice(0, 2), ...points.slice(4, 6)]
      : points.slice(0, 4)
  )
  const firstLineParts = divideLineToParts(firstLine)

  //second line is another part of this from the end of second arrow till the last coords
  const secondLinePart = [
    ...firstLineParts[1].slice(2, 4),
    ...points.slice(2, 6),
  ]
  const secondLineAmount = straightLine ? secondLinePart : points.slice(2, 6)
  const secondLine = transformPointsToLineCoords(secondLineAmount)
  const secondLineParts = divideLineToParts(secondLine)
  const endByX = x + points[points.length - 2]
  const endByY = y + points[points.length - 1]
  const deviation = canvasWidth > 400 ? desckDeviation : mobDeviation

  function getCaptionPosition() {
    const isCoordOverEnd = x > endByX
    const shiftOverEndValue = isBigContainer
      ? 60
      : isBigContainer && !isSmall
      ? 45
      : 30
    const topXShift = isBigContainer ? 25 : 15
    const topYShift = isBigContainer ? 25 : isBigContainer && !isSmall ? 15 : 15
    const anotherYShift = isBigContainer ? 15 : 7

    switch (caption.x) {
      case 'start':
        if (rotation) {
          return {
            captionX: isCoordOverEnd
              ? endByX + shiftOverEndValue
              : endByX + topXShift,
            captionY: caption.y === 'top' ? y : y + anotherYShift,
          }
        } else {
          return {
            captionX: isCoordOverEnd ? x - shiftOverEndValue : x,
            captionY: caption.y === 'top' ? y - topYShift : y + anotherYShift,
          }
        }
      case 'center':
        if (rotation) {
          const xPositiveRotation = isCoordOverEnd
            ? endByX + shiftOverEndValue
            : endByX + topXShift
          const xNegativeRotation = isCoordOverEnd
            ? endByX + shiftOverEndValue
            : endByX - topXShift
          const yPositiveRotation = (y + endByY) / 2
          const yNegativeRotation = (y + endByY) / 2
          const xCoords = rotation >= 0 ? xPositiveRotation : xNegativeRotation
          const Ycoords = rotation >= 0 ? yPositiveRotation : yNegativeRotation
          return {
            captionX: xCoords,
            captionY: Ycoords,
          }
        } else {
          return {
            captionX: isCoordOverEnd
              ? (x + endByX) / 2 + deviation.centerCaptionX
              : (x + endByX) / 2 - deviation.centerCaptionX,
            captionY: caption.y === 'top' ? y - topYShift : y + anotherYShift,
          }
        }
      case 'end':
        if (rotation) {
          const xPositiveRotation = isCoordOverEnd
            ? endByX + shiftOverEndValue
            : endByX + topXShift
          const xNegativeRotation = isCoordOverEnd
            ? endByX + shiftOverEndValue
            : endByX - topXShift
          const isDirectionUp = y > endByY
          const yDecrementPositive = isDirectionUp ? 5 : -55
          const yDecrementNegative = isDirectionUp ? 55 : -5

          const yPositiveRotation = endByY + yDecrementPositive
          const yNegativeRotation = endByY + yDecrementNegative
          const xCoords = rotation >= 0 ? xPositiveRotation : xNegativeRotation
          const Ycoords = rotation >= 0 ? yPositiveRotation : yNegativeRotation
          return {
            captionX: xCoords,
            captionY: Ycoords,
          }
        } else {
          return {
            captionX: isCoordOverEnd
              ? endByX + shiftOverEndValue
              : endByX - shiftOverEndValue,
            captionY:
              caption.y === 'top' ? endByY - topYShift : endByY + anotherYShift,
          }
        }
    }
  }

  const { captionX, captionY } = getCaptionPosition()
  return (
    <>
      {firstLineParts.map((coords) => (
        <Arrow
          x={x}
          y={y}
          key={`${name}: ${coords}`}
          points={coords}
          strokeWidth={2}
          pointerLength={arrowPointerSizes.height}
          pointerWidth={arrowPointerSizes.width}
          stroke="#EA5449"
          fill="#EA5449"
        />
      ))}
      {/*straight line render*/}
      {straightLine && (
        <Arrow
          x={x}
          y={y}
          points={secondLinePart}
          strokeWidth={2}
          pointerLength={arrowPointerSizes.height}
          pointerWidth={arrowPointerSizes.width}
          stroke="#EA5449"
          fill="#EA5449"
        />
      )}

      {/*render for curve arrow last part that consists from 3 another smaller parts*/}

      {/* render first part(pre arrows) of 3 */}
      {!straightLine && (
        <Line
          x={x}
          y={y}
          points={[...firstLineParts[1].slice(2, 4), ...points.slice(2, 4)]}
          strokeWidth={2}
          stroke="#EA5449"
          fill="#EA5449"
        />
      )}

      {/* render main part(arrows) of 3 */}
      {!straightLine &&
        secondLineParts.map((coords) => (
          <Arrow
            x={x + secondLineAmount[0]}
            y={y + secondLineAmount[1]}
            key={`${name}: ${coords}`}
            points={coords}
            strokeWidth={2}
            pointerLength={arrowPointerSizes.height}
            pointerWidth={arrowPointerSizes.width}
            stroke="#EA5449"
            fill="#EA5449"
          />
        ))}
      {/* render last part(after arrows) of 3 */}
      {!straightLine && (
        <Arrow
          x={x + secondLineAmount[0]}
          y={y + secondLineAmount[1]}
          points={[
            ...secondLineParts[1].slice(2, 4),
            secondLineAmount[2] - secondLineAmount[0],
            secondLineAmount[3] - secondLineAmount[1],
          ]}
          strokeWidth={2}
          pointerLength={arrowPointerSizes.height}
          pointerWidth={arrowPointerSizes.width}
          stroke="#EA5449"
          fill="#EA5449"
        />
      )}
      <Text
        x={captionX}
        y={captionY}
        text={caption.text}
        zIndex={captionZindex}
        fontSize={fontSizeAdaptive}
        rotation={rotation}
        {...arrowCaptionTextSettings}
      />
    </>
  )
}

export default EnergyFlowArrow
