import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import classnames from 'classnames'
import { useMsal } from '@azure/msal-react'

import { useGroup } from '../../context/Group.jsx'

import Card from '../../layouts/Card/Card.jsx'
import PersonalInfo from '../../components/PersonalInfo/PersonalInfo.jsx'

import personalStyles from '../../components/PersonalInfo/PersonalInfo.module.scss'
import './Profile.scss'


const Profile = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { groupData } = useGroup()
  const { groupsList, activeGroup } = groupData
  const { accounts } = useMsal()
  const { name } = accounts[0]
  let contactInfo = {
    contactName: '',
    contactPhone: '',
    contactCompany: '',
    name: ''
  }

  if ( groupsList && activeGroup ) {
    contactInfo = groupsList.filter(obj => obj.id.toString() === activeGroup.project_info_id)[0]
  }

  const installerInfo = {
    name: {
      value: contactInfo.contactName,
      label: 'Name',
    },
    company: {
      value: contactInfo.contactCompany,
      label: 'Company',
    },
    phone: {
      value: contactInfo.contactPhone,
      label: 'Phone Number',
    },
  }
  
  const userInfo = {
    // email: {
    //   value: 'johnny.doe@gmail.com',
    //   label: 'Email address',
    // },
    name: {
      value: name.split('.').join(' '),
      label: 'Name',
    },
    // company: {
    //   value: 'Smarthub',
    //   label: 'Company',
    // },
    // phone: {
    //   value: '+61 491 570 156',
    //   label: 'Phone Number',
    // },
  }

  return (
    <Grid
      container
      direction="row"
      rowSpacing={isSmall ? 2 : 3}
      columnSpacing={isSmall ? 0 : 2}
    >
      <Grid
        item
        xs={12}
        lg={6}
      >
        <Card
          singleCard
          orientation="start-column"
          innerTitle="Personal Info"
        >
          <PersonalInfo userInfo={userInfo} />
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
      >
        <Card
          singleCard
          orientation="start-column"
          innerTitle="Contact"
        >
          <div className={personalStyles.description}>
            {Object.keys(installerInfo).map((key) => {
              const value = installerInfo[key].value
              const label = installerInfo[key].label

              return (
                <div
                  className={personalStyles.descriptionRow}
                  key={label}
                >
                  <h4 className={personalStyles.descriptionRowHeader}>
                    {label}:
                  </h4>
                  {key === 'phone' ? (
                    <a
                      className={classnames(
                        personalStyles.link,
                        personalStyles.descriptionRowValue
                      )}
                      href={`tel:${value.replace(/\s/g, '')}`}
                    >
                      {value}
                    </a>
                  ) : (
                    <span className={personalStyles.descriptionRowValue}>
                      {value}
                    </span>
                  )}
                </div>
              )
            })}
          </div>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Card
          singleCard
          orientation="start-column"
          innerTitle="Site List"
        >
          {groupsList.map((group) => (
            <div
              className={personalStyles.descriptionRow}
              key={group.id}
            >
              <span className={personalStyles.descriptionRowValue}>
                {group.name}
              </span>
            </div>
          ))}
        </Card>
      </Grid>
    </Grid>
  )
}

export default Profile
