import SvgIcon from '@mui/material/SvgIcon'

const OperationsIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
    >
      <path
        d="M15.1855 7.9927C15.1855 9.48694 14.7277 10.9273 13.8527 12.1659C13.6508 12.4485 13.4355 12.7179 13.1661 12.9871C12.9239 13.2428 12.6277 13.4987 12.3719 13.7005C12.1027 13.9025 11.7661 14.1179 11.4565 14.2929C11.12 14.4813 10.8237 14.616 10.5007 14.7371C9.04682 15.2621 7.4987 15.3295 6.03136 14.9121C4.63132 14.5083 3.39282 13.7005 2.45049 12.5563C1.53509 11.4659 0.956224 10.012 0.84853 8.58503C0.72737 7.06383 1.07738 5.58302 1.85817 4.29067C2.61204 3.03871 3.72937 2.056 5.07557 1.43675C6.40829 0.830962 7.9968 0.655963 9.4372 0.952123C10.8911 1.24828 12.1835 1.96176 13.2065 3.02526C14.2163 4.08875 14.8893 5.42147 15.1181 6.90228C15.1451 7.21191 15.1855 7.62923 15.1855 7.9927C15.1855 8.51771 15.9932 8.51771 15.9932 7.9927C15.9932 6.35035 15.4816 4.72146 14.5259 3.37527C13.5835 2.056 12.2643 1.04635 10.7431 0.480949C9.18145 -0.0979043 7.41793 -0.151757 5.81597 0.305949C4.28132 0.736736 2.88128 1.65215 1.85817 2.87718C0.794677 4.15606 0.16197 5.7311 0.0273569 7.38691C-0.10727 9.02926 0.296584 10.7255 1.15816 12.1389C1.97933 13.4987 3.21782 14.616 4.6717 15.2756C6.21982 15.9756 7.94296 16.1776 9.61226 15.8409C11.1873 15.5179 12.6681 14.6967 13.7855 13.5389C14.9028 12.3813 15.6567 10.8736 15.8989 9.28504C15.9663 8.86772 16.0067 8.43694 16.0067 8.01962C15.9932 7.48115 15.1855 7.48115 15.1855 7.9927Z"
        fill={props.color}
      />
      <path
        d="M7.56592 2.3928C7.56592 2.75628 7.56592 3.11975 7.56592 3.48322C7.56592 3.53707 7.56592 3.59092 7.56592 3.6313C7.56592 3.8467 7.75439 4.04862 7.96977 4.03516C8.18517 4.0217 8.37364 3.86015 8.37364 3.6313C8.37364 3.26783 8.37364 2.90436 8.37364 2.54088C8.37364 2.48704 8.37364 2.43319 8.37364 2.3928C8.37364 2.17742 8.18517 1.97548 7.96977 1.98895C7.74092 1.98895 7.56592 2.16395 7.56592 2.3928Z"
        fill={props.color}
      />
      <path
        d="M2.7331 8.26146C3.09659 8.26146 3.46005 8.26146 3.82352 8.26146C3.87737 8.26146 3.93121 8.26146 3.9716 8.26146C4.187 8.26146 4.38892 8.07299 4.37547 7.85759C4.362 7.6422 4.20045 7.45374 3.9716 7.45374C3.60813 7.45374 3.24466 7.45374 2.88118 7.45374C2.82734 7.45374 2.77349 7.45374 2.7331 7.45374C2.51772 7.45374 2.31578 7.6422 2.32925 7.85759C2.34272 8.08644 2.50425 8.26146 2.7331 8.26146Z"
        fill={props.color}
      />
      <path
        d="M3.98542 4.27673C4.24119 4.5325 4.49696 4.78829 4.75274 5.04406C4.79313 5.08445 4.82006 5.11137 4.86045 5.15175C4.92775 5.21906 5.04891 5.27291 5.14314 5.27291C5.23738 5.27291 5.35852 5.23253 5.42584 5.15175C5.49315 5.07098 5.54699 4.97675 5.54699 4.86906C5.54699 4.76137 5.5066 4.66713 5.42584 4.58635C5.17006 4.33058 4.91428 4.07479 4.65851 3.81902C4.61812 3.77863 4.5912 3.75171 4.55082 3.71133C4.48351 3.64402 4.36235 3.59018 4.26811 3.59018C4.17388 3.59018 4.05272 3.63055 3.98542 3.71133C3.91811 3.7921 3.86426 3.88634 3.86426 3.99403C3.86426 4.08826 3.90464 4.19595 3.98542 4.27673Z"
        fill={props.color}
      />
      <path
        d="M13.2606 7.45374C12.8971 7.45374 12.5336 7.45374 12.1702 7.45374C12.1163 7.45374 12.0624 7.45374 12.022 7.45374C11.8067 7.45374 11.6048 7.6422 11.6183 7.85759C11.6316 8.07299 11.7932 8.26146 12.022 8.26146C12.3855 8.26146 12.7491 8.26146 13.1126 8.26146C13.1663 8.26146 13.2202 8.26146 13.2606 8.26146C13.476 8.26146 13.6779 8.07299 13.6644 7.85759C13.651 7.6422 13.4895 7.45374 13.2606 7.45374Z"
        fill={props.color}
      />
      <path
        d="M11.443 3.69843C11.1872 3.95422 10.9314 4.20999 10.6757 4.46576C10.6353 4.50615 10.6084 4.53307 10.568 4.57346C10.5006 4.64076 10.4468 4.76192 10.4468 4.85615C10.4468 4.95039 10.4872 5.07155 10.568 5.13886C10.6488 5.20616 10.743 5.26002 10.8506 5.26002C10.9584 5.26002 11.0526 5.21963 11.1334 5.13886C11.3892 4.88308 11.6449 4.62731 11.9008 4.37152C11.941 4.33115 11.968 4.30422 12.0084 4.26383C12.0757 4.19652 12.1296 4.07536 12.1296 3.98114C12.1296 3.8869 12.0892 3.76575 12.0084 3.69843C11.9277 3.63112 11.8334 3.57727 11.7257 3.57727C11.618 3.59074 11.5238 3.63112 11.443 3.69843Z"
        fill={props.color}
      />
      <path
        d="M8.11808 10.0121C8.83156 9.21793 9.55851 8.42369 10.272 7.62944C10.3797 7.52175 10.474 7.40059 10.5816 7.29289C10.7297 7.13135 10.7432 6.87557 10.5816 6.72749C10.4336 6.57941 10.1508 6.56595 10.0162 6.72749C9.30274 7.52175 8.57579 8.316 7.86231 9.11025C7.75462 9.21795 7.66038 9.33907 7.55268 9.4468C7.4046 9.6084 7.39115 9.86413 7.55268 10.0121C7.70076 10.1603 7.97 10.1872 8.11808 10.0121Z"
        fill={props.color}
      />
      <path
        d="M8.8989 10.1199C8.8989 10.7256 8.34697 11.2103 7.71425 11.0891C7.32386 11.0083 7.00077 10.6987 6.93346 10.3083C6.87961 10.0256 6.94693 9.75639 7.09501 9.55439C7.12193 9.51399 7.18923 9.54093 7.18923 9.59479C7.17578 9.78333 7.25655 10.0256 7.35078 10.1064C7.9431 10.6987 8.53543 10.0256 8.71043 9.75639C8.73735 9.71599 8.81813 9.71599 8.83159 9.76986C8.87198 9.87746 8.8989 9.99866 8.8989 10.1199Z"
        fill={props.color}
      />
    </SvgIcon>
  )
}

export default OperationsIcon
