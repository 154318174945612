const weatherStatuses = (status) => {
  switch (status) {
    case 'Sunny':
    case 'Mostly Sunny':
    case 'Hot':
    case 'Clear':
    case 'Mostly Clear':
      return 'sun'
    case 'Partly Sunny':
    case 'Hazy Sunshine':
    case 'Partly Cloudy':
    case 'Hazy Moonlight':
    case 'Partly Cloudy with Showers':
      return 'partlyCloudy'
    case 'Intermittent Clouds':
    case 'Mostly Cloudy':
    case 'Cloudy':
    case 'Dreary (Overcast)':
    case 'Fog':
    case 'Windy':
      return 'cloud'
    case 'Mostly Cloudy with Showers':
    case 'Partly Sunny with Showers':
    case 'Rain':
      return 'rain'
    case 'Thunderstorms':
    case 'Mostly Cloudy with Thunderstorms':
    case 'Partly Sunny with Thunderstorms':
    case 'Partly Cloudy with Thunderstorms':
      return 'thunderstorm'
    case 'Flurries':
    case 'Mostly Cloudy with Flurries':
    case 'Partly Sunny with Flurries':
    case 'Snow':
    case 'Mostly Cloudy with Snow':
    case 'Ice':
    case 'Sleet':
    case 'Freezing Rain':
    case 'Rain and Snow':
    case 'Cold':
      return 'snow'
  }
}

export default weatherStatuses
