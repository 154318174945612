import ProgressToNetChart from '../../components/charts/ProgressToNetChart/ProgressToNetChart.jsx'

import styles from './ProgressToNet.module.scss'
import commonStyles from '../../shared/scss/common.module.scss'

const ProgressToNet = () => {
  return (
    <div className={commonStyles.w100}>
      <div className={styles.header}>
        Progress to Net 0<div className={styles.subHeader}>(kW)</div>
      </div>
      <ProgressToNetChart />
    </div>
  )
}

export default ProgressToNet
