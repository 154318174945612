import { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication,
} from '@azure/msal-react'

import { loginRequest } from './utils/authConfig'

import { useGroup } from './context/Group.jsx'
import DashboardLayout from './layouts/DashboardLayout.jsx'
import Login from './pages/Login/Login.jsx'
import Home from './pages/Home/Home.jsx'
import Sites from './pages/Sites/Sites.jsx'
import Performance from './pages/Performance/Performance.jsx'
import Operations from './pages/Operations/Operations.jsx'
import Reports from './pages/Reports/Reports.jsx'
import Profile from './pages/Profile/Profile.jsx'

import './App.scss'

const App = () => {
  const { groupActions } = useGroup()
  const { instance, accounts, inProgress } = useMsal(useMsalAuthentication())
  const isAuth = useIsAuthenticated()

  useEffect(() => {
    const fetchData = async () => {
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      const { accessToken, idToken } = response
      const userData = await groupActions.getUserGroups(accessToken, idToken)
      await groupActions.changeActiveGroup(
        {
          id: userData.id,
        },
        accessToken,
        idToken
      )
    }
    if (isAuth) {
      fetchData().catch((err) => {
        console.error('UNABLE to load data', err)
      })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth])

  const authUser = () => {
    instance.loginPopup(loginRequest).catch((e) => {
      console.log(e)
    })
  }

  const innerPages = [
    {
      path: '/sites',
      component: <Sites />,
    },
    {
      path: '/performance',
      component: <Performance />,
    },
    {
      path: '/operations',
      component: <Operations />,
    },
    {
      path: '/reports',
      component: <Reports />,
    },
    {
      path: '/profile',
      component: <Profile />,
    },
  ]

  const requiresLogin =
    !isAuth && (inProgress === 'none' || inProgress === 'login')
  return (
    <Routes>
      {/* main page route */}
      <Route
        path="/"
        element={
          requiresLogin ? (
            <Login
              authUser={authUser}
              inProgress={inProgress}
            />
          ) : (
            <DashboardLayout>
              <Home />
            </DashboardLayout>
          )
        }
        exact="true"
      />

      {/* inner pages */}
      {innerPages.map(({ path, component }) => (
        <Route
          path={path}
          element={
            requiresLogin ? (
              <Login
                authUser={authUser}
                inProgress={inProgress}
              />
            ) : (
              <DashboardLayout>{component}</DashboardLayout>
            )
          }
          key={path}
          exact="true"
        />
      ))}
      {/* another routes that doesn't match */}
      <Route
        path="*"
        element={
          requiresLogin ? (
            <Login
              authUser={authUser}
              inProgress={inProgress}
            />
          ) : (
            <DashboardLayout>
              <Home />
            </DashboardLayout>
          )
        }
      />
    </Routes>
  )
}

export default App
