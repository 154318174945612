import { useState } from 'react'
import classnames from 'classnames'

import ArrowDown from '../../assets/img/arrow_down.svg'

import styles from './Select.module.scss'

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex */
const Select = ({
  list = [],
  onChange,
  currentValue,
  defaultLabel,
  defaultValue,
}) => {
  const [selectOpened, setSelectState] = useState(false)
  const handleClose = (value) => {
    if (typeof value === 'number') {
      onChange(value)
    }
    setSelectState(false)
  }
  const handleToggle = () => {
    setSelectState(!selectOpened)
  }

  return (
    <div
      tabIndex={0}
      className={classnames(styles.select, {
        [styles.opened]: selectOpened,
      })}
      onClick={handleToggle}
      onBlur={handleClose}
    >
      <div className={styles.selectValue}>
        <div>{currentValue === defaultValue ? defaultLabel : currentValue}</div>
        <img
          src={ArrowDown}
          className={styles.selectIcon}
          alt="dropdown arrow"
        />
      </div>

      <div className={styles.selectListWrapper}>
        <ul className={styles.selectList}>
          {list.map((listElem) => (
            <li
              className={styles.selectListItem}
              key={listElem}
              value={listElem}
              onClick={(e) => handleClose(e.target.value)}
            >
              {listElem == defaultValue ? defaultLabel : listElem}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Select
