import { Image, Text } from 'react-konva'
import useImage from 'use-image'

import { solarPanelStatuses } from '../../../utils/constants/solarPanelStatuses'
import { textSettings, titleSettings } from '../../../constants/energyFlowChart'
import solarOffline from '../../../assets/img/panels/offline.svg'
import solarOnline from '../../../assets/img/panels/online.svg'
import solarSubOptimal from '../../../assets/img/panels/sub-optimal.svg'
import solarOptimal from '../../../assets/img/panels/optimal.svg'

const desckDeviation = {
  imgX: 0,
  leftTextX: 5,
  rightTextX: 70,
  additionalFromImgY: 0,
}
const mobDeviation = {
  imgX: 10,
  leftTextX: 0,
  rightTextX: 50,
  additionalFromImgY: -5,
}

function Solar({ x, y, imgWidth, imgHeight, canvasHeight, fontSize, data }) {
  const { size, output, status } = data
  const isPositive = status === solarPanelStatuses.optimal
  const isSubOptimal = status === solarPanelStatuses.subOptimal
  const isOnline = status === solarPanelStatuses.online
  const imageUrl = isOnline
    ? solarOnline
    : isPositive
    ? solarOptimal
    : isSubOptimal
    ? solarSubOptimal
    : solarOffline
  const [image] = useImage(imageUrl)
  const deviation = imgWidth == 66 ? mobDeviation : desckDeviation
  const centerY = canvasHeight / 2
  const rowHeight = 20
  const rowMargin = 8

  return (
    <>
      <Text
        y={centerY - imgHeight / 2 - 36 - deviation.additionalFromImgY}
        width={imgWidth}
        x={deviation.imgX}
        align="center"
        text="Solar"
        fontSize={fontSize}
        {...titleSettings}
      />
      <Image
        x={x + deviation.imgX}
        y={y}
        width={imgWidth}
        height={imgHeight}
        image={image}
      />
      <Text
        x={x + deviation.leftTextX}
        y={
          centerY +
          imgHeight / 2 +
          rowHeight +
          rowMargin +
          deviation.additionalFromImgY
        }
        text="Size:"
        fontSize={fontSize}
        {...textSettings}
      />
      <Text
        x={x + deviation.rightTextX}
        y={
          centerY +
          imgHeight / 2 +
          rowHeight +
          rowMargin +
          deviation.additionalFromImgY
        }
        text={`${Math.round(size * 100) / 100} kW`}
        fontSize={fontSize}
        {...titleSettings}
      />
      <Text
        x={x + deviation.leftTextX}
        y={
          centerY +
          imgHeight / 2 +
          (rowHeight + rowMargin) * 2 +
          deviation.additionalFromImgY
        }
        text="Output:"
        fontSize={fontSize}
        {...textSettings}
      />
      <Text
        x={x + deviation.rightTextX}
        y={
          centerY +
          imgHeight / 2 +
          (rowHeight + rowMargin) * 2 +
          deviation.additionalFromImgY
        }
        text={`${Math.round(output*100)/100} kW`}
        fontSize={fontSize}
        {...titleSettings}
      />
    </>
  )
}

export default Solar
