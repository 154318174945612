import { Image, Text } from 'react-konva'
import useImage from 'use-image'

import gridImg from '../../../assets/img/grid.svg'
import { titleSettings, textSettings } from '../../../constants/energyFlowChart'

const desckDeviation = {
  imgY: 30,
  textY: 160,
}

const mobDeviation = {
  imgY: 20,
  textY: 90,
}

function Grid({ x, y, imgWidth, imgHeight, canvasWidth, fontSize, value }) {
  const [image] = useImage(gridImg)
  const deviation = imgWidth == 80 ? desckDeviation : mobDeviation
  return (
    <>
      <Text
        text="Grid"
        align="center"
        y={y}
        width={canvasWidth}
        fontSize={fontSize}
        {...titleSettings}
      />
      <Image
        x={x}
        y={deviation.imgY}
        width={imgWidth}
        height={imgHeight}
        fontSize={fontSize}
        image={image}
      />
      <Text
        text={`${Math.round(value * 100) / 100} c per kwh`}
        align="center"
        width={canvasWidth}
        y={deviation.textY}
        fontSize={fontSize}
        {...textSettings}
      />
    </>
  )
}

export default Grid
