export const titleSettings = {
  fontFamily: 'Inter',
  fontStyle: '700',
  fill: '#001B40',
}

export const textSettings = {
  fontFamily: 'Inter',
  fontStyle: '400',
  fill: '#001B40',
}

export const arrowCaptionTextSettings = {
  fontFamily: 'Inter',
  fontStyle: '600',
  fill: '#EA5449',
}
