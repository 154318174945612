import { numberFormatting } from '../../helpers/numberFormatting'

import styles from './AmountWithLabel.module.scss'

const AmountWithLabel = ({ header, value, type }) => {
  const res = type === 'tonnes' ? `${value}t` : `$${numberFormatting(value)}`

  return (
    <div className={styles.mainWrapper}>
      {header && <div className={styles.header}>{header}</div>}
      <div className={styles.wrapper}>
        <span className={styles.value}>{res}</span>
      </div>
    </div>
  )
}

export default AmountWithLabel
