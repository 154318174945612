import axios from 'axios'

import { apiUrl } from '../utils/constants/variables'

const httpService = axios.create({
  baseURL: apiUrl,
})

httpService.interceptors.request.use(
  (request) => {
    return request
  },
  function (error) {
    return Promise.reject(error)
  }
)

httpService.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

const get = (url, config, service = httpService) => service.get(url, config)

const post = (url, payload, config, service = httpService) =>
  service.post(url, payload, config)

const put = (url, payload, config, service = httpService) =>
  service.put(url, payload, config)

const httpDelete = (url, config, service = httpService) =>
  service.delete(url, config)

export { httpService, get, post, put, httpDelete }
