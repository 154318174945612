import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import classnames from 'classnames'

import { useGroup } from '../../context/Group.jsx'

import batteryIcon from '../../assets/img/battery-icon.svg'
import solarIcon from '../../assets/img/solar-icon.svg'
import flexIcon from '../../assets/img/flex-icon.svg'

import Card from '../../layouts/Card/Card.jsx'

import styles from './NetActions.module.scss'

const defaultData = {
  annual_savings: 0,
  battery: 0,
  solar: 0,
  flex: 0,
}

const NetActions = () => {
  const { groupHelpers, groupData } = useGroup()
  const { battery, solar, flex } = groupHelpers.getActiveGroupData(
    'potential_savings',
    defaultData
  )

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.header}>
        Net 0 Actions
        <a
          className={classnames(styles.contactBtn, styles.desktop)}
          href="mailto:"
        >
          Contact Us
        </a>
      </h2>

      {!groupData.isLoading ? (
        <Grid
          container
          direction="column"
          spacing={2}
        >
          {solar > 0 && (
            <Grid item>
              <Card
                singleCard
                orientation="start"
              >
                <div className={styles.action}>
                  <img
                    src={solarIcon}
                    className={styles.actionImg}
                    alt="solar"
                  />
                  <div>Expand PV up to {solar}kW</div>
                </div>
              </Card>
            </Grid>
          )}
          {battery > 0 && (
            <Grid item>
              <Card
                singleCard
                orientation="start"
              >
                <div className={styles.action}>
                  <img
                    src={batteryIcon}
                    className={styles.actionImg}
                    alt="battery"
                  />
                  <div>Expand battery {battery}kW</div>
                </div>
              </Card>
            </Grid>
          )}
          {flex > 0 && (
            <Grid item>
              <Card
                singleCard
                orientation="start"
              >
                <div className={styles.action}>
                  <img
                    src={flexIcon}
                    className={styles.actionImg}
                    alt="flex"
                  />
                  <div>Expand flex {flex}kW</div>
                </div>
              </Card>
            </Grid>
          )}
        </Grid>
      ) : (
        <Box>
          <Skeleton
            variant="rounded"
            height={300}
            sx={{
              borderRadius: 14,
            }}
          ></Skeleton>
        </Box>
      )}

      <a
        className={classnames(styles.contactBtn, styles.mobile)}
        href="mailto:"
      >
        Contact Us
      </a>
    </div>
  )
}

export default NetActions
