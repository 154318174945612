import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import classnames from 'classnames'

import { useGroup } from '../../context/Group.jsx'
import Select from '../Select/Select.jsx'

import pdfUrl from '../../assets/test.pdf'
import xlsUrl from '../../assets/test.xls'
import pdfIcon from '../../assets/img/pdf.svg'
import xlsIcon from '../../assets/img/excel.svg'

import styles from './QuarterReports.module.scss'

const reports = [
  {
    name: 'Q1 2022 report',
    pdf: pdfUrl,
    xls: xlsUrl,
  },
  {
    name: 'Q2 2022 report',
    pdf: pdfUrl,
    xls: xlsUrl,
  },
  {
    name: 'Q3 2022 report',
    pdf: pdfUrl,
    xls: xlsUrl,
  },
  {
    name: 'Q4 2022 report',
    pdf: pdfUrl,
    xls: xlsUrl,
  },
]
const yearsReports = [2022, 2021, 2020, 2019, 2018, 2017]
const currentYear = new Date().getFullYear()

const QuarterReports = () => {
  const [year, setYear] = useState(currentYear)
  useEffect(() => {
    console.log('year changed, request data', year)
  }, [year])
  const { groupData } = useGroup()
  const handleChange = (value) => {
    setYear(value)
  }
  const tableRender = (
    <div className={styles.table}>
      <div className={styles.tableHeader}>
        <div className={classnames(styles.tableCell, styles.count)}>qrt</div>
        <div className={classnames(styles.tableCell, styles.content)}>
          SmartHub pdf
        </div>
        <div className={classnames(styles.tableCell, styles.xls)}>xls</div>
      </div>
      <div className={styles.tableBody}>
        {reports.map((report, i) => (
          <div
            className={styles.tableRow}
            key={report.name}
          >
            <div className={classnames(styles.tableCell, styles.count)}>
              {i + 1}
            </div>
            <div className={classnames(styles.tableCell, styles.content)}>
              <a
                href={pdfUrl}
                className={styles.pdfIcon}
                download
              >
                <img
                  src={pdfIcon}
                  alt={`download pdf: ${report.name}`}
                />
              </a>
              {report.name}
            </div>
            <div className={classnames(styles.tableCell, styles.xls)}>
              <a
                href={xlsUrl}
                download
              >
                <img
                  src={xlsIcon}
                  className={styles.xlsIcon}
                  alt={`download xls: ${report.name}`}
                />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.headerMain}>
          <h2 className={styles.header}>Quarterly Reports</h2>
          <h3 className={styles.subHeader}>
            Download Quarterly Smarthub Reports
          </h3>
        </div>

        <div className={styles.select}>
          <Select
            list={yearsReports}
            currentValue={year}
            defaultValue={currentYear}
            defaultLabel="Current Year"
            onChange={handleChange}
          />
        </div>
      </div>

      {groupData.isLoading ? (
        <Box>
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              height: '270px',
              maxWidth: '100%',
              borderRadius: 5,
            }}
          >
            {tableRender}
          </Skeleton>
        </Box>
      ) : (
        tableRender
      )}
    </>
  )
}

export default QuarterReports
